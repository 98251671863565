const CryptoJS = require("crypto-js");

const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_CRYPTO_IV);

class Crypto {
  crypto(text) {
    const str = CryptoJS.AES.encrypt(text, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
    }).toString();
    return Buffer.from(str, "utf8").toString();
  }

  decrypto(text) {
    try {
      if (text) {
        const decrypted = CryptoJS.AES.decrypt(text, key, {
          iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding,
        });
        return CryptoJS.enc.Utf8.stringify(decrypted).toString();
      } else {
        return "";
      }
    } catch (err) {
      console.error(err);
      return "";
    }
  }
}

export default new Crypto();
