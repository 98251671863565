<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row row_sign_up">
              <div v-if="!sendSuccess" class="col-12 col-xl-6 col-md-12 d-flex col-sign-up">
                <div class="inner">
                  <h1 class="hdng lg_size">立即注册</h1>
                  <p class="cntnt"></p>
                </div>

                <section class="contact_sec">
                  <div class="form_wrap">
                    <form id="contactForm">
                      <div class="row">
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label for="email"
                              >公司电子邮件 <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              class="form_control"
                              name="email"
                              id="email"
                              placeholder="例如：anthony@gmail.com"
                              v-model="signUpFormData.formData.email"
                              @input="checkUserEmail"
                              maxlength="100"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorEmail"
                              class="errorInput"
                            >
                              请输入公司电子邮件
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <SelectCountry ref="selectCountry" :isZh="true" v-model:countryId="signUpFormData.formData.countryId"/>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label>名字 <span class="required">*</span></label>
                            <input
                              type="text"
                              class="form_control"
                              placeholder="名字 "
                              v-model="signUpFormData.formData.firstName"
                              @input="checkUserFName"
                              maxlength="20"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorFirstName"
                              class="errorInput"
                            >
                              请输入名字
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label>姓氏 <span class="required">*</span></label>
                            <input
                              type="text"
                              class="form_control"
                              placeholder="姓氏"
                              v-model="signUpFormData.formData.lastName"
                              @input="checkUserLName"
                              maxlength="20"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorLastName"
                              class="errorInput"
                            >
                              请输入姓氏
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form_field">
                            <label>公司名字 <span class="required">*</span></label>
                            <input
                              type="text"
                              class="form_control"
                              placeholder="请输入公司名称"
                              v-model="signUpFormData.formData.companyName"
                              @input="checkUserCompAdr"
                              maxlength="1024"
                            />
                            <!-- <div
                              v-if="!signUpFormData.errors.showErrorCompanyWeb"
                              class="errorInput"
                            >
                              请输入公司名称
                            </div> -->
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form_field">
                            <label
                              for="check_privacy_policy"
                              class="checkbox_wrap"
                            >
                              <input
                                type="checkbox"
                                name="check_privacy_policy"
                                id="check_privacy_policy"
                                value="accepted"
                                v-model="termsCheck"
                              />
                              <span class="check_text">
                                我确认并同意遵守 NXCLOUD的
                                <router-link to="/zh-privacy-policy">
                                  隐私政策
                                </router-link>
                                和
                                <router-link to="/zh-terms-services">
                                  服务协议
                                </router-link>
                              </span>
                            </label>
                            <div
                              v-if="!signUpFormData.errors.showErrorTermsCheck"
                              class="errorInput"
                            >
                              请检查此字段
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="submit_wrap">
                            <div class="submitWrapper signUp">
                              <input
                                type="submit"
                                value="注册"
                                @click="submitSubLoginForm"
                              />
                              <!-- Loader -->
                              <div
                                v-if="signUpFormData.errors.isLoginSubmit"
                                class="loader"
                              >
                                <div class="tm-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="signUpFormData.errors.messageAfterSubmit"
                              :class="{
                                error: signUpFormData.errors.messageAfterSubmit,
                              }"
                              class="messageAfterSubmit"
                            >
                              {{ signUpFormData.errors.messageAfterSubmit }}
                            </div>
                            <p class="info">
                              已经是现有用户？
                              <router-link to="/zh-login">
                                <u>在此登录</u>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
              <div v-else class="col-12 col-xl-6 col-md-12 d-flex col-sign-up">
                  <div class="inner">
                    <h1
                      class="hdng lg_size"
                    >
                      感谢您的注册！
                    </h1>
                    <p
                      class="cntnt"
                    ></p>
                  </div>

                  <div class="sign-up-success-card">
                    我们已向发送激活电子邮件
                    <span>{{ signUpFormData.formData.email }}</span>.若要继续，请检查您的电子邮件并验证您的帐户。 
                    <br/>
                    如果您没有看到，请检查您的垃圾邮件文件夹。
                  </div>

                  <span class="sign-up-tip-1">没有收到激活邮件？</span>

                  <div class="submitWrapper signUp" style="margin-top: 30px;">
                    <input class="submit-btn" type="submit" value="重新发送" @click="submitSubLoginForm"/>
                    <!-- Loader -->
                    <div v-if="signUpFormData.errors.isLoginSubmit" class="loader">
                      <div class="tm-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                  </div>
                </div>
              <div class="col-12 col-xl-6 col-md-12 mt-4 mt-md-0">
                <img src="../../assets/images/register_banner_2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn" @click="scrollTop">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import util from "@/libs/util.js";
import SelectCountry from "../components/selectCountry.vue";

export default {
  name: "ZhSignUp",
  components: {SelectCountry},
  data() {
    return {
      sendSuccess: false,
      termsCheck: [],
      signUpFormData: {
        errors: {
          showErrorEmail: true,
          showErrorFirstName: true,
          showErrorLastName: true,
          showErrorCompanyWeb: true,
          showErrorTermsCheck: true,
          isSignUpSubmit: false,
          messageAfterSubmit: "",
        },
        formData: {
          email: "",
          firstName: "",
          lastName: "",
          countryId: "",
          companyAddress: "",
        },
      },
    };
  },
  mounted() {
    
  },
  methods: {
    checkUserEmail() {
      if (!this.signUpFormData.formData.email.trim()) {
        this.signUpFormData.errors.showErrorEmail = false;
      } else {
        this.signUpFormData.errors.showErrorEmail = true;
      }
    },
    checkUserFName() {
      if (!this.signUpFormData.formData.firstName.trim()) {
        this.signUpFormData.errors.showErrorFirstName = false;
      } else {
        this.signUpFormData.errors.showErrorFirstName = true;
      }
    },
    checkUserLName() {
      if (!this.signUpFormData.formData.lastName.trim()) {
        this.signUpFormData.errors.showErrorLastName = false;
      } else {
        this.signUpFormData.errors.showErrorLastName = true;
      }
    },
    checkUserCompAdr() {
      if (!this.signUpFormData.formData.companyAddress.trim()) {
        this.signUpFormData.errors.showErrorCompanyWeb = false;
      } else {
        this.signUpFormData.errors.showErrorCompanyWeb = true;
      }
    },
    submitSubLoginForm(event) {
      event.preventDefault();

      //  check if fields are empty
      if (!this.signUpFormData.formData.email.trim()) {
        this.signUpFormData.errors.showErrorEmail = false;
        return
      }
      if (!this.signUpFormData.formData.countryId) {
        this.$refs.selectCountry.showErrorCountryM();
        return
      }
      if (!this.signUpFormData.formData.firstName.trim()) {
        this.signUpFormData.errors.showErrorFirstName = false;
        return
      }
      if (!this.signUpFormData.formData.lastName.trim()) {
        this.signUpFormData.errors.showErrorLastName = false;
        return
      }
      // if (!this.signUpFormData.formData.companyAddress.trim()) {
      //   this.signUpFormData.errors.showErrorCompanyWeb = false;
      //   return;
      // }
      if (!this.termsCheck[0]) {
        this.signUpFormData.errors.showErrorTermsCheck = false;
        return;
      }

      this.signUpFormData.errors.isLoginSubmit = true;

      // if(this.userFormData.formData.account != "" && this.userFormData.formData.password != ""){
      axios.post(`${util.didApi}/register/sendEmail`, this.signUpFormData.formData, {
          headers: {
            "Content-Language": "CN",
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
            console.log(response);
            this.signUpFormData.errors.isLoginSubmit = false;
            this.signUpFormData.errors.showErrorTermsCheck = true;
            if(response.data.code === 0) {
              // this.signUpFormData.errors.messageAfterSubmit = "success";
              this.sendSuccess = true;
              this.behaviorRecord();
            }else {
              this.signUpFormData.errors.messageAfterSubmit = response.data.msg;
            }
        })
        .catch(error => {
          console.error(error);
        });
    },
    behaviorRecord() {
      const platform = require('platform');
      const nxAccount = this.signUpFormData.formData.email.replace("@", "%40%");
      // eslint-disable-next-line
      gtag('event', "提交注册", {
        user_id: nxAccount,
        nxAccount: nxAccount,
        nxEquipment: platform.os.family,
        nxSource: "中文官网",
        nxWhereFrom: this.$store.state.whereFrom,
      });
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    },
  },
};
</script>

<style>

</style>
