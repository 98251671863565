<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-7 mt-4 mt-md-0">
                <img src="../../assets/images/login_banner.png" alt="" />
              </div>
              <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">
                <div class="inner w-100">
                  <h1 class="hdng">Login</h1>

                  <div class="formTab">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="login-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#login-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="login-tab-pane"
                          aria-selected="true"
                        >
                          Sign In
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="employee-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#employee-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="employee-tab-pane"
                          aria-selected="false"
                        >
                          Employee Sign In
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="login-tab-pane"
                        role="tabpanel"
                        aria-labelledby="login-tab"
                        tabindex="0"
                      >
                        <form action="">
                          <label for="phone">
                            <input
                              type="text"
                              id="user-phone"
                              autocomplete="on"
                              placeholder="Phone (country code + phone) / Email / Username"
                              v-model="userFormData.formData.account"
                              @input="checkUserField"
                            />
                            <div
                              v-if="!userFormData.errors.showErrorUser"
                              class="errorInput"
                            >
                              Please Enter Phone/Email/Username
                            </div>
                          </label>

                          <label for="password">
                            <div class="password-root">
                              <input
                                :type="isShowPwd ? 'text' : 'password'"
                                id="user-password"
                                placeholder="Password"
                                autocomplete="current-password"
                                v-model="userFormData.formData.password"
                                @input="checkPassField"
                              />
                              <img class="password-eye" 
                              :src="isShowPwd ? require(`@/assets/images/eye_open.svg`) : require(`@/assets/images/eye_close.svg`)" 
                                alt="eye"
                                @click="isShowPwd = !isShowPwd" />
                            </div>
                            <div
                              v-if="!userFormData.errors.showErrorPass"
                              class="errorInput"
                            >
                              Please Enter Password
                            </div>
                          </label>

                          <div class="row captcha_fp">
                            <div class="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0">
                              <!-- <img
                                src="../../assets/images/captcha.png"
                                alt=""
                              /> -->
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0">
                              <router-link to="/forget-password">
                                Forget password?
                              </router-link>
                            </div>
                          </div>

                          <div class="submitWrapper">
                            <button
                              class="cmn_btn light"
                              @click="submitLoginForm"
                            >
                              Login
                              <svg
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                                  stroke="#85C100"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                            <!-- Loader -->
                            <div
                              v-if="userFormData.errors.isLoginSubmit"
                              class="loader"
                            >
                              <div class="tm-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="userFormData.errors.messageAfterSubmit"
                            :class="{
                              error: userFormData.errors.messageAfterSubmit,
                            }"
                            class="messageAfterSubmit"
                          >
                            {{ userFormData.errors.messageAfterSubmit }}
                          </div>

                          <p class="info">
                            Don't have an account?
                            <router-link to="/sign-up">
                              Sign up now
                            </router-link>
                          </p>
                        </form>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="employee-tab-pane"
                        role="tabpanel"
                        aria-labelledby="employee-tab"
                        tabindex="0"
                      >
                        <form action="">
                          <label for="phone">
                            <input
                              type="text"
                              id="emp-phone"
                              placeholder="Mobile Number"
                              v-model="empFormData.formData.phone"
                              @input="checkEmpPhoneField"
                            />
                            <div
                              v-if="!empFormData.errors.showErrorPhone"
                              class="errorInput"
                            >
                              Please Enter Mobile Number
                            </div>
                          </label>

                          <label for="e_username">
                            <input
                              type="text"
                              id="emp-username"
                              placeholder="Employee Username"
                              v-model="empFormData.formData.code"
                              @input="checkEmpAccountField"
                            />
                            <div
                              v-if="!empFormData.errors.showErrorUser"
                              class="errorInput"
                            >
                              Please Enter Employee Username
                            </div>
                          </label>

                          <label for="password">
                            <input
                              type="password"
                              id="emp-password"
                              placeholder="Password"
                              v-model="empFormData.formData.password"
                              @input="checkEmpPassField"
                            />
                            <div
                              v-if="!empFormData.errors.showErrorPass"
                              class="errorInput"
                            >
                              Please Enter Password
                            </div>
                          </label>

                          <div class="row captcha_fp">
                            <div class="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0">
                              <!-- <img
                                src="../../assets/images/captcha.png"
                                alt=""
                              /> -->
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0">
                              <router-link to="/forget-password">
                                Forget password?
                              </router-link>
                            </div>
                          </div>

                          <div class="submitWrapper">
                            <button
                              class="cmn_btn light"
                              @click="submitSubLoginForm"
                            >
                              Login
                              <svg
                                width="18"
                                height="19"
                                viewBox="0 0 18 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                                  stroke="#85C100"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>

                            <!-- Loader -->
                            <div
                              v-if="empFormData.errors.isLoginSubmit"
                              class="loader"
                            >
                              <div class="tm-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="empFormData.errors.messageAfterSubmit"
                            :class="{
                              error: empFormData.errors.messageAfterSubmit,
                            }"
                            class="messageAfterSubmit"
                          >
                            {{ empFormData.errors.messageAfterSubmit }}
                          </div>

                          <p class="info">
                            Don't have an account?
                            <router-link to="/sign-up">
                              Sign up now
                            </router-link>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>
          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Cookies from "cookies-js";
import util from "@/libs/util.js";

export default {
  name: "ZhLogin",
  data() {
    return {
      isShowPwd: false,
      userFormData: {
        errors: {
          showErrorUser: true,
          showErrorPass: true,
          isLoginSubmit: false,
          messageAfterSubmit: "",
        },
        formData: {
          account: "",
          password: "",
        },
      },
      empFormData: {
        errors: {
          showErrorPhone: true,
          showErrorUser: true,
          showErrorPass: true,
          isLoginSubmit: false,
          messageAfterSubmit: "",
        },
        formData: {
          phone: "",
          password: "",
          code: "",
        },
      },
    };
  },
  methods: {
    // user form code
    // display errors on input
    checkUserField() {
      if (!this.userFormData.formData.account.trim()) {
        this.userFormData.errors.showErrorUser = false;
      } else {
        this.userFormData.errors.showErrorUser = true;
      }
      this.resetSubmitMessage();
    },
    checkPassField() {
      if (!this.userFormData.formData.password.trim()) {
        this.userFormData.errors.showErrorPass = false;
      } else {
        this.userFormData.errors.showErrorPass = true;
      }
      this.resetSubmitMessage();
    },
    // display errors on input

    // submit login form
    submitLoginForm(event) {
      event.preventDefault();

      //  check if fields are empty
      if (!this.userFormData.formData.account.trim()) {
        this.userFormData.errors.showErrorUser = false;
      }
      if (!this.userFormData.formData.password.trim()) {
        this.userFormData.errors.showErrorPass = false;
        return;
      }
      //  check if fields are empty

      this.userFormData.errors.isLoginSubmit = true;

      axios
        .post(
          `${util.baseAdmin}/api/public/userLogin`,
          this.userFormData.formData,
          {
            headers: {
              "Content-Language": "EN",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.userFormData.errors.isLoginSubmit = false;
          if(response.data.code === 0) {
            this.result(response.data.data);
            Cookies.set("sub_flag", 0);
          }else {
            this.userFormData.errors.messageAfterSubmit = response.data.msg;
          }
        })
        .catch((error) => {
          console.error(error);
        });
      // userLogin(this.userFormData.formData).then((response) => {
      //   if (response.data.code == 0) {
      //     this.result(response.data.data);
      //     Cookies.set("sub_flag", 0);
      //   } else {
      //     // this.$Message.error(response.data.msg);
      //     console.error(response.data.msg);
      //   }
      // });
      
    },

    // Sub User Login
    checkEmpPhoneField() {
      if (!this.empFormData.formData.phone.trim()) {
        this.empFormData.errors.showErrorPhone = false;
      } else {
        this.empFormData.errors.showErrorPhone = true;
      }
      this.resetSubmitMessage();
    },
    checkEmpAccountField() {
      if (!this.empFormData.formData.code.trim()) {
        this.empFormData.errors.showErrorUser = false;
      } else {
        this.empFormData.errors.showErrorUser = true;
      }
      this.resetSubmitMessage();
    },
    checkEmpPassField() {
      if (!this.empFormData.formData.password.trim()) {
        this.empFormData.errors.showErrorPass = false;
      } else {
        this.empFormData.errors.showErrorPass = true;
      }
      this.resetSubmitMessage();
    },
    resetSubmitMessage() {
      this.userFormData.errors.messageAfterSubmit = "";
      this.empFormData.errors.messageAfterSubmit = "";
    },
    // submit login form
    submitSubLoginForm(event) {
      event.preventDefault();

      //  check if fields are empty
      if (!this.empFormData.formData.phone.trim()) {
        this.empFormData.errors.showErrorPhone = false;
      }
      if (!this.empFormData.formData.code.trim()) {
        this.empFormData.errors.showErrorUser = false;
      }
      if (!this.empFormData.formData.password.trim()) {
        this.empFormData.errors.showErrorPass = false;
        return;
      }
      //  check if fields are empty

      this.empFormData.errors.isLoginSubmit = true;

      axios
        .post(
          `${util.api}/subCustomerLogin`,
          `phone=${this.empFormData.formData.phone}&code=${this.empFormData.formData.code}&password=${this.empFormData.formData.password}`,
          {
            headers: {
              "Content-Language": "EN",
              // "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.empFormData.errors.isLoginSubmit = false;
          if(res.data.code === "success") {
            const menuLists = [].concat(
              ...((res.data &&
                res.data.row &&
                res.data.row.menulist.map((item) => item.sub_menu)) ||
                [])
            );
            let redirect = "";
            redirect = (menuLists.find((item) => item.show_flag === 1) || {})
                .menu_path;

            this.result(res.data.row, { path: redirect || "error-403" });
            Cookies.set("sub_flag", 1);
          }else {
            this.empFormData.errors.messageAfterSubmit = res.data.info;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // Sub User Login

    result(
      data,
      redirect = {
        path: decodeURIComponent(
          "/console/home"
        ),
      }
    ) {
      const result = {
        ...data.customer,
        ...data,
      };
      Cookies.set("user", result.name);
      Cookies.set("usertoken", result.usertoken);
      Cookies.set("currency", result.currency);
      Cookies.set("balance", result.balance);
      Cookies.set("credit_balance", result.credit_balance);
      Cookies.set("subUserToken", result.subUserToken);
      Cookies.set("enable_product", result.enable_product);
      // Cookies.set("phone", takePhone(result.phone));
      Cookies.set("uin", result.id);
      let menuList = result.menulist;
      if (localStorage.lang) {
        localStorage.setItem("lang", localStorage.lang);
      } else {
        localStorage.setItem("lang", "zh-CN");
      }
      localStorage.setItem("menuLists", JSON.stringify(menuList));
      if(redirect.path.includes("/client")) {
        window.location.href = redirect.path;
      }else {
        window.location.href = `/client${redirect.path}`;
      }
    },
  },
  mounted() {
    if(this.$route.query && this.$route.query.account) {
      this.userFormData.formData.account = this.$route.query.account;
    }
    if(this.$route.query && this.$route.query.password) {
      this.userFormData.formData.password = this.$route.query.password;
    }
  },
};
</script>
<style scoped>
</style>