import { createI18n } from 'vue-i18n'
import en from './en-US.json'
import zh from './zh-CN.json'

const messages = {
  "zh-CN": zh,
  "en-US": en,
}

const i18n = createI18n({
  locale: 'en-US', // 默认语言
  fallbackLocale: 'en-US', // 备用语言
  messages,
})

export default i18n