<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">The right voice for your business</h1>
                  <p class="cntnt">
                    Dynamic voice platform for you to send and receive calls
                    across the world.
                  </p>
                  <router-link to="/sign-up" class="cmn_btn light">
                    Start now
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/product_voice.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">
                    Your trusted business voice
                  </h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>Worldwide Coverage</strong>
                      <span
                        >Get access to our 165+ countries with multi-language
                        capabilities.
                      </span>
                    </li>
                    <li>
                      <strong>Accelerated Velocity</strong>
                      <span
                        >Worldwide server deployment which allows for
                        millisecond transmission for your customers.
                      </span>
                    </li>
                    <li>
                      <strong>Global Partners</strong>
                      <span
                        >Connect with our 1,000+ global partner operators to
                        reach out to your customers anywhere, anytime.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">Trustable voice authentication</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#voiceOtp"
                  type="button"
                  role="tab"
                  aria-controls="voiceOtp"
                  aria-selected="true"
                >
                  <strong> Voice OTP </strong>
                  <span>
                    Secure authentication via phone call for verification
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#voiceNotification"
                  type="button"
                  role="tab"
                  aria-controls="voiceNotification"
                  aria-selected="false"
                >
                  <strong> Voice Notification </strong>
                  <span>
                    Keep your customers informed with audio recording
                    notifications
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-3"
                  data-bs-toggle="tab"
                  data-bs-target="#sipTrunk"
                  type="button"
                  role="tab"
                  aria-controls="sipTrunk"
                  aria-selected="false"
                >
                  <strong> SIP Trunk </strong>
                  <span>
                    Send and receive calls via voice over internet protocol
                    (VOIP)
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="voiceOtp"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/effortless_security.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Effortless Security</h4>
                    <p class="cntnt">
                      Ensure genuine user sign-ups by sending voice verification
                      codes to mobile numbers for quick access.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/forget_me_not.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Forget Me Not</h4>
                    <p class="cntnt">
                      Prompt automatic transmission of voice verification codes
                      to the registered mobile number, confirming user identity
                      and safeguarding privacy.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Payment_icon.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Payment <br />
                      Authentication
                    </h4>
                    <p class="cntnt">
                      SMS verification for user identity authentication during
                      online transactions to prevent financial loss.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="voiceNotification"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Your_personal_secretary.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Your Personal <br />
                      Secretary
                    </h4>
                    <p class="cntnt">
                      Broadcast priority meeting notifications via outbound
                      calls, swiftly sharing meeting specifics like time,
                      location, theme, and notable information.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Available_at_every_touchpoint.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Available at <br />
                      Every Touchpoint
                    </h4>
                    <p class="cntnt">
                      Enhance your consumer satisfaction and minimize customer
                      loss by using voice notifications to send delivery status
                      updates and feedback requests.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Education_made_simpler.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Education <br />
                      Made Simpler
                    </h4>
                    <p class="cntnt">
                      Remind students about class schedules or new course
                      releases to achieve wider information dissemination whilst
                      reducing operational expenses.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="sipTrunk"
                role="tabpanel"
                aria-labelledby="tab-3"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Uninterrupted_customer_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Uninterrupted <br />
                      Customer Service
                    </h4>
                    <p class="cntnt">
                      Swiftly address customer inquiries and complaints by
                      connecting to business call centers and service hotlines
                      via SIP Trunk.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Global_marketing_reach.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Global Marketing <br />
                      Reach
                    </h4>
                    <p class="cntnt">
                      Instant global connectivity and high-quality voice
                      services to support your telemarketing efforts to boost
                      sales conversion.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Worldwide_coverage.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Worldwide Coverage</h4>
                    <p class="cntnt">
                      Reach your customers across the world in seconds to keep
                      them connected with you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ProductVoice",
};
</script>


