<template>
  <div v-if="fromChina">
    <div v-if="!isMobile" class="nxcloud-footer-copyright">
      <!-- <div class="nxcloud-footer-division"></div>
      <div class="nxcloud-footer-copyright-info" style="margin-top: 28px">
        © 2015-2024 宁波牛信网络科技有限公司 All Rights Reserved.
      </div>
      <div class="nxcloud-footer-copyright-info">
        增值电信业务经营许可证编号： B2-20172130 |<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16041108号-2</a>
      </div> -->
    </div>
    <div v-else class="layout-footer">
      <div class="foot-bg">
        <div class="img-bg">
          <img src="./images/footer-logo.svg" />
        </div>
      </div>
      <!-- <span>© Copyright by 2015-2024 nxcloud.com<br />
        牛信网络科技有限公司
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "common-footer-icp",
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {  
    fromChina() {
      return this.$store.state.fromChina;  
    },
  },  
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 575.98;
    },
    // async checkFromChina() {
    //   // 文件在中国服务器上的 URL
    //   const chinaFileUrl = 'https://www.nxcloud.com/flag.json';
    //   // const chinaFileUrl = '/api/flag.json';

    //   // 发送请求检查是否能访问中国服务器上的文件
    //   fetch(chinaFileUrl)
    //     .then(async(response) => {
    //       console.log(response)
    //       if (response.ok) {
    //         // 请求成功，说明在中国境内
    //         // console.log('用户在中国境内');
    //         const data = await response.json();
    //         console.log(data);
    //         if(data === 1) {
    //           this.fromChina = true;
    //         }else {
    //           this.fromChina = false;
    //         }
    //       } else {
    //         // 请求失败，可能是在国外或其他问题
    //         // console.log('用户在国外或其他问题');
    //         this.fromChina = false;
    //       }
    //     })
    //     .catch(() => {
    //       // 请求失败，说明不在中国境内
    //       // console.log('用户不在中国境内。');
    //       this.fromChina = false;
    //     });
    // },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    // this.checkFromChina();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="less" scoped>
.nxcloud-footer-copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nxcloud-footer-division {
  width: 840px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  // margin: 42px 0 24px;
  margin-top: 42px;
}

.nxcloud-footer-copyright-info {
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 32px;

  a {
    color: rgba(255, 255, 255, 0.6);
  }
}

.layout-footer {
  padding: 32px 24px 80px;
  // background: #0C1B30;
  text-align: center;

  h1 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 15px;
  }

  &-ewm {
    width: 120px;
    height: 120px;
  }

  .foot-bg {
    height: 19px;
    position: relative;
    text-align: center;

    .img-bg {
      // background: #0C1B30;
      display: inline-block;
      height: 19px;
      width: 180px;
      z-index: 99;
      position: relative;
    }

    &::after {
      position: absolute;
      content: '';
      border-top: 1px solid rgba(125, 138, 163, 0.5);
      width: 100%;
      top: 0px;
      left: 0;
    }
  }

  p {
    font-size: 12px;
    color: #fff;
    line-height: 24px;
    margin: 6px 0 38px;
    color: #7D8AA3;
  }

  span {
    margin-top: 7px;
    display: block;
    line-height: 24px;
    font-size: 10px;
    color: #7D8AA3;
  }

  .footer-btn {
    display: block;
    height: 44px;
    background: #0265F2;
    color: #fff;
    line-height: 44px;
    margin: 0 auto 29px;
  }


  dl {
    dt {
      color: #999999;
      font-size: 12px;
      margin-bottom: 16px;
    }

    a {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      margin-right: 24px;
      margin-bottom: 10px;
    }
  }
}</style>