<template>
    <section class="error-404 not-found">
        <div class="container">
            <div class="box">
                <div class="inner">
                    <h1>404</h1>
                    <h2>哎呀！页面未找到</h2>
                    <p>对不起，您要查找的页面不存在。</p>
                </div>
                <div class="btn-wrapper">
                    <router-link to="/zh" class="btn">
                        首页
                    </router-link>
                </div>
            </div>
        </div>
    </section>


    <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
            <div class="row row-cts">
                <div class="col-md-8">
                    <h3 class="archived-hdng cts-hdng">
                        开始为您的客户提供 <br />
                        更优质的服务体验
                    </h3>
                </div>

                <div class="col-md-4">
                    <div class="cts-btn">
                        <router-link to="/zh-sign-up" class="btn btn-success">
                            开始使用
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "zhPageNotFound"
    }
</script>