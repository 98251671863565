<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">以全渠道通信方式触 <br />达全球用户</h1>
                  <p class="cntnt">
                    通过短信、WhatsApp、Viber等多种方式与客户沟通交流。
                  </p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/product_conversation_banner.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">我们的优势</h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>行业渗透深</strong>
                      <span
                        >沉淀丰富的行业经验，为全球10000+公司提供服务。
                      </span>
                    </li>
                    <li>
                      <strong>覆盖范围广</strong>
                      <span
                        >业务覆盖全球185多个国家和地区，支持多种语言。
                      </span>
                    </li>
                    <li>
                      <strong>官方认证</strong>
                      <span
                        >牛信云是WhatsApp官方认证的商业解决方案提供商。
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">全渠道无缝链接全球用户</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#superMessaging"
                  type="button"
                  role="tab"
                  aria-controls="superMessaging"
                  aria-selected="true"
                >
                  <strong> Super Messaging API </strong>
                  <span> 全渠道对话API，<br />助力企业全球业务拓展。 </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#whatsappBusiness"
                  type="button"
                  role="tab"
                  aria-controls="whatsappBusiness"
                  aria-selected="false"
                >
                  <strong> WhatsApp Business</strong>
                  <span> 基于 WhatsApp能力，<br />高效触达全球25亿用户。 </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-3"
                  data-bs-toggle="tab"
                  data-bs-target="#viberBusiness"
                  type="button"
                  role="tab"
                  aria-controls="viberBusiness"
                  aria-selected="false"
                >
                  <strong> Viber Business </strong>
                  <span>
                    基于Viber 能力，提供商业化 <br />客户即时通信服务。
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-4"
                  data-bs-toggle="tab"
                  data-bs-target="#zaloBusiness"
                  type="button"
                  role="tab"
                  aria-controls="zaloBusiness"
                  aria-selected="false"
                >
                  <strong> Zalo Notification Service</strong>
                  <span>
                    通过Zalo商业通知服务，<br />精准触达超90%越南人口。
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="superMessaging"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Omnichannel_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">全渠道营销</h4>
                    <p class="cntnt">
                      通过全渠道能力依次投放宣传，<br />最大化触达客户，更好地提升<br />品牌知名度和销售转化。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Customer_first.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">客户服务</h4>
                    <p class="cntnt">
                      及时通知物流信息、订单信息等，实时沟通和互动，迅速解决客户问题，<br />提升客户体验。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Business_growth.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">商务对话</h4>
                    <p class="cntnt">
                      提高商务对话效率，促进销售谈判、合同确认和凭证收发的高效准确进行。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="whatsappBusiness"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Connected_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">客服对话</h4>
                    <p class="cntnt">
                      集成WhatsApp通信能力，企业和消费者可以无缝沟通，及时解决客户咨询与投诉等问题，全面提升客户满意度。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/End-to-End.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">售后通知</h4>
                    <p class="cntnt">
                      推送交易订单、物流信息、会员积分变动提醒等售后通知信息，提供极致的售后服务体验，降低客户流失。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Targeted_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">二次营销</h4>
                    <p class="cntnt">
                      向老客户发送优惠券、复购折扣、新产品推荐等营销类信息，激活沉睡用户，实现二次转化目标。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="viberBusiness"
                role="tabpanel"
                aria-labelledby="tab-3"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Connected_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">客服对话</h4>
                    <p class="cntnt">
                      集成Viber通信能力，企业和消费者可以无缝沟通，及时解决咨询与投诉等问题，全面提升客户满意度。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/End-to-End.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">售后通知</h4>
                    <p class="cntnt">
                      推送交易订单、物流信息、会员积分变动提醒等售后通知信息，提供极致的售后服务体验，降低客户流失。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Targeted_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">二次营销</h4>
                    <p class="cntnt">
                      向老客户发送优惠券、复购折扣、新产品推荐等营销类信息，激活沉睡用户，实现二次转化目标。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="zaloBusiness"
                role="tabpanel"
                aria-labelledby="tab-4"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Connected_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">帐号验证</h4>
                    <p class="cntnt">
                      通过Zalo向用户发送验证码通知，<br />验证用户身份，以保障帐户信息安全。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/End-to-End.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">订单通知</h4>
                    <p class="cntnt">
                      及时将订单信息、交易信息、<br />服务账单等信息通知客户，<br />为客户提供优质的服务体验。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Targeted_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">售后调研</h4>
                    <p class="cntnt">
                      向客户发送售后评价通知，邀请客户<br />对产品和订单服务进行整体评价，<br />调研客户满意度。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ZhProductConversation",
};
</script>
