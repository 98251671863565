import axios from "axios";
import util from "@/libs/util.js";

function loadScript(callback) {
  (function (d, s, id) {
    if (!document.getElementById("captcha-element")) {
      const element = document.createElement("div");
      element.id = "captcha-element";
      document.getElementsByTagName("body")[0].appendChild(element);
    }
    let script = document.getElementById(id);
    if (script) {
      script.remove();
      const popup = document.getElementById("aliyunCaptcha-window-popup");
      if (popup) popup.remove();
      const mask = document.getElementById("aliyunCaptcha-mask");
      if (mask) mask.remove();
    }
    let fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    script = d.createElement(s);
    script.id = id;
    script.src =
      "https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js";
    fjs.parentNode.insertBefore(script, fjs);

    script.onload = function () {
      callback && callback();
    };
  })(document, "script", "aliyun-captcha");
}

class AliyunCapcha {
  initAliyunCaptcha({
    button,
    captchaVerifyCallback,
    onBizResultCallback,
    language = "zh-CN",
  }) {
    loadScript(() => {
      let captcha;
      // 弹出式
      // eslint-disable-next-line no-undef
      initAliyunCaptcha({
        SceneId: "emkrib7i", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: "q0wrtm", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: "#captcha-element", // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button, // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: { "zh-CN": "cn", "en-US": "en" }[language], // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });

      // 绑定验证码实例函数。该函数为固定写法，无需修改
      function getInstance(instance) {
        captcha = instance;
      }
    });
  }

  async verify(params, lang = "EN") {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${util.didApi}/nxapi/public/verCodeByCaptcha`,
          params,
          {
            headers: {
              "Content-Language": lang,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(err);
        });
    });
    // axios
  //       .post(
  //         `${util.did}/nxapi/public/verCodeByCaptcha`,
  //         params,
  //         {
  //           headers: {
  //             "Content-Language": "EN",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       )
  //   const data = await http(
  //     { url: "/nxapi/public/verCodeByCaptcha", base: "did" },
  //     params
  //   ).catch((err) => {
  //     console.error(err);
  //     return false;
  //   });
  //   return data;
  }
}

function AliyunCapchaFactory() {
  if (!AliyunCapchaFactory.data) {
    AliyunCapchaFactory.data = new AliyunCapcha();
  }
  return AliyunCapchaFactory.data;
}

export default AliyunCapchaFactory();
