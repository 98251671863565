<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">出海游戏解决方案</h1>
                  <p class="cntnt">为出海游戏提供全流程的通信服务</p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img src="../../assets/images/industry/game.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>为出海游戏企业定制完整的 <br />行业解决方案</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/lower_cost_arrow.svg"
                    alt="lower_cost_arrow."
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>降低获客成本</h3>
                    <p>
                      将新用户注册福利、充值优惠等营销信息发送给消费者，附带游戏下载 短链接进行用户引流，降低获客成本，提高ROI。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/boost_user_loyalty.svg"
                    alt="boost_user_loyalty"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>提高用户留存</h3>
                    <p>
                      策划游戏升级福利、节日营销等活动，通过多种产品将相关信息传递给老用户，与用户建立粘性，快速实现沉睡用户召回。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/account_protection.svg"
                    alt="account_protection"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>强化帐户安全</h3>
                    <p>
                      通过多种验证方式，有效保障用户帐号安全，降低盗号风险。同时，避免羊毛党使用设备薅羊毛，避免造成企业运营资源浪费。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_4_2 pt_100">
      <div class="container_1700">
        <div class="sec_heading text-center">
          <h2>客户全旅程服务</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_acquisition.svg"
                    alt="customer_acquisition"
                  />
                </div>
                <div class="text_wrap">
                  <h3>1. 引流获客</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'zaloBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/zalo_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'marketingOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="marketingOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_02.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">营销短信</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/secure_logins.svg"
                    alt="secure_logins"
                  />
                </div>
                <div class="text_wrap">
                  <h3>2. 帐号登录</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'smsOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="smsOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/sms_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">短信验证码</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp验证</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-voice',
                        query: { id: 'voiceOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="voiceOtp"
                        data-url="../zh-product-voice"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/voice_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">语音验证码</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-fraud-prevention',
                        query: { id: 'OTA' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="OTA"
                        data-url="../zh-product-fraud-prevention"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_12.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">一键登录</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_benifits.svg"
                    alt="customer_benifits"
                  />
                </div>
                <div class="text_wrap">
                  <h3>3. 玩家运营</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'zaloBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/zalo_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'marketingOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="marketingOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_02.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">营销短信</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_4">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_service.svg"
                    alt="course_notification"
                  />
                </div>
                <div class="text_wrap">
                  <h3>4. 玩家服务</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'zaloBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/zalo_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'marketingOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="marketingOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_09.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">通知短信</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey_new pt_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img
                  src="../../assets/images/industry_gaming_journey.svg"
                  alt="industry_gaming_journey"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pt_100 pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>产品推荐</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Voice-otp.svg"
                    alt="voice_OTP"
                  />
                </div>
                <div class="text_wrap">
                  <h3>语音验证码</h3>
                  <p>
                    以电话语音播报的方式向用户发送随机数字验证码，验证用户身份，可有效保障用户帐号信息安全，同时有效防止平台被薅羊毛。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/voice-notification.svg"
                    alt="voice_notifications"
                  />
                </div>
                <div class="text_wrap">
                  <h3>语音通知</h3>
                  <p>
                    通过语音外呼自动播报给用户，向企业用户和会员传递通知信息，节省人工成本，提升工作效率。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Whatsapp.svg"
                    alt="whattsap"
                  />
                </div>
                <div class="text_wrap">
                  <h3>WhatsApp Business</h3>
                  <p>
                    支持快速接入企业营销管理平台、呼叫中心、客服系统等应用，帮助企业进行客户服务和产品二次营销，高效触达目标用户。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Marketing-sms.svg"
                    alt="marketing_SMS"
                  />
                </div>
                <div class="text_wrap">
                  <h3>营销短信</h3>
                  <p>
                    通过短信向消费者发送特价提示、优惠券、促销活动等营销类信息，可附带网站或平台短链接，是一种可实现精准获客的营销方式。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success"
                >开始使用</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ZhIndustryGaming",
};
</script>
