<template>
  <main>
    <section class="developer_page">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="sidebar_area_left">
              <div class="search_wrap">
                <form>
                  <input
                    type="text"
                    class="search_field"
                    placeholder="Document Keyword search..."
                    v-model="docSearchVal"
                    @input="docSearchFun()"
                  />
                  <button class="search_btn"></button>
                </form>
                <p>
                  <i>
                    <a
                      href="https://github.com/nxtele/nxcloud-doc-en/wiki"
                      target="_blank"
                      >Click Here For More Updates</a
                    >
                  </i>
                </p>
              </div>
              <div class="sidebar_options_wrap">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_message"
                      aria-expanded="true"
                      aria-controls="collapse_message"
                    >
                      <img src="../../assets/images/chat_dark.svg" />
                      <img
                        src="../../assets/images/message_hovered.svg"
                        class="hovered"
                      />
                      Message
                    </button>
                    <div
                      id="collapse_message"
                      class="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/chat_lock.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>SMS OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.sms"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/bell_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Email OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.email_code"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_voice"
                      aria-expanded="false"
                      aria-controls="collapse_voice"
                    >
                      <img src="../../assets/images/voice_dark.svg" />
                      <img
                        src="../../assets/images/voice_hovered.svg"
                        class="hovered"
                      />
                      Voice
                    </button>
                    <div
                      id="collapse_voice"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Voice OTP</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.voice1"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/speaker_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>Voice Notification </b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.voice2"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/others_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Others</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.voice3"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_flash_call"
                      aria-expanded="false"
                      aria-controls="collapse_flash_call"
                    >
                      <img src="../../assets/images/conversation_icon_2.svg" />
                      <img
                        src="../../assets/images/conversation_hovered.svg"
                        class="hovered"
                      />
                      Conversation
                    </button>
                    <div
                      id="collapse_flash_call"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>WhatsApp Messages</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li v-for="(menu, index) of menuData.wa" :key="index">
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>WhatsApp Management</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li v-for="(menu, index) of menuData.wa1" :key="index">
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>WhatsApp Integrator</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li v-for="(menu, index) of menuData.wa2" :key="index">
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/whattsap2_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Viber Business</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.viber"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/messenger_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Messenger Business</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.messenger"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/zolo_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Zalo Business</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.zalo"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_short_link"
                      aria-expanded="false"
                      aria-controls="collapse_short_link"
                    >
                      <img src="../../assets/images/fraud_icon_2.svg" />
                      <img
                        src="../../assets/images/fraud__hovered.svg"
                        class="hovered"
                      />
                      Fraud Prevention
                    </button>
                    <div
                      id="collapse_short_link"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/frequency_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Number Check</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.number_detection"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_email_verification"
                      aria-expanded="false"
                      aria-controls="collapse_email_verification"
                    >
                      <img src="../../assets/images/global-number_icon_2.svg" />
                      <img
                        src="../../assets/images/number_hovered.svg"
                        class="hovered"
                      />
                      Global Number
                    </button>
                    <div
                      id="collapse_email_verification"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/glasses_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Privacy Number</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.pns"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/both_side_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>DID Number</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.did_number"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#customer-engagment"
                      aria-expanded="false"
                      aria-controls="customer-engagment"
                    >
                      <img
                        src="../../assets/images/customer_engagement_icon_2.svg"
                      />
                      <img
                        src="../../assets/images/customer_engagement_hovered.svg"
                        class="hovered"
                      />
                      Customer Engagement
                    </button>
                    <div
                      id="customer-engagment"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>NXLink</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.nxlink"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"
                                  ><b>AICC(Call center)</b></span
                                >
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.nxcc"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/lock_icon.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>AICC(Call bot)</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.callbot"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse_others"
                      aria-expanded="false"
                      aria-controls="collapse_others"
                    >
                      <img src="../../assets/images/others_dark.svg" />
                      <img
                        src="../../assets/images/others_dark_hovered.svg"
                        class="hovered"
                      />
                      Others
                    </button>
                    <div
                      id="collapse_others"
                      class="accordion-collapse collapse hide"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/agency_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Agent (Legacy)</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.nxphone"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/exclamation_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>Short Link</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.short_chain"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="icon_item">
                                <i class="icon">
                                  <img
                                    src="../../assets/images/exclamation_dark.svg"
                                    alt="icon"
                                  />
                                </i>
                                <span class="text"><b>General</b></span>
                              </div>
                            </a>
                          </li>
                          <li
                            v-for="(menu, index) of menuData.general"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              target="_blank"
                              :data-github="menu.url"
                            >
                              {{ menu.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="resultNotFound" class="resultNotFound">
                  <p class="alert alert-danger" role="alert">
                    Sorry, we couldn't find any results
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <!-- <div class="center_area hide_temp">
              <img src="../../assets/images/centre_image.png" class="w-100" />
            </div> -->
            <div class="center_area">
              <div v-if="markdownContent === ''" class="git_section_area"></div>
              <div v-else class="git_section_area">
                <!-- <div class="markdown-body" v-html="markdownContent"></div> -->
                <v-md-preview
                  class="markdown-body"
                  :text="markdownContent"
                ></v-md-preview>
              </div>
              <div class="pageLoad_loader" style="display: none"></div>
            </div>
          </div>
          <div class="col-lg-3 hide_temp">
            <div class="sidebar_area_right">
              <div class="table_content">
                <h2>Table of contents</h2>
                <ul>
                  <li>Brief Description</li>
                  <li>Request Method</li>
                  <li>Request Example</li>
                  <li>Response Example</li>
                  <li>Response Parameter Description</li>
                  <li>Note</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { menuData } from "@/components/pages/document/api-en/index.js";
export default {
  name: "DeveloperPage",

  data() {
    return {
      markdownContent: "",
      currBtnData: "",
      docSearchVal: "",
      resultNotFound: false,
      githubPagesPath: "/github_pages/",
      mdData: "",
      menuData
    };
  },
  watch: {
    "$route.path": {
      handler: "getMdFile",
      immediate: true
    },
    "$route": {
      handler: "scrollToView",
      immediate: true
    }
  },
  methods: {
    async getDataApiByMd(url) {
      try {
        console.log("url: ", url);
        // Assuming url includes the correct path
        const markdownFile = await import(`!!raw-loader!/${url}.md`);
        this.markdownContent = markdownFile.default;
      } catch (error) {
        console.error("Error loading Markdown file:", error);
      }
    },

    // html append from file into center area appendDataToCenterArea()
    appendDataToCenterArea(htmlContent) {
      document.querySelector(".git_section_area").innerHTML = htmlContent;
    },
    async getMdFile() {
      window.scrollTo(0, 0);
      // this.mdFile = null;
      const category = this.$route.params.catogory;
      const doc = this.$route.params.doc || "index";
      console.log("category", category);
      console.log("doc", doc);
      const mdName = `document/api-en/${category}/${doc}`;
      this.getDataApi(mdName);
      this.$nextTick(() => {
        this.setDomSelect(mdName);
      });
    },
    async setDomSelect(mdName) {
      const [fileName, parentDir] = mdName.split("/").reverse();
      const result =
        parentDir && fileName
          ? `/${parentDir}/${fileName}`
          : "/sms/charging-standard";
      let elements = document.querySelectorAll(`[data-github="${result}"]`);
      if (elements.length > 0) {
        let parentElement = elements[0].closest(".accordion-item");
        let btn = parentElement.querySelector(".accordion-button.collapsed");
        if (btn) {
          btn.click();
        }
        elements[0].click();
      }
    },
    // getDataApi function for get HTML data from github pages  {github pages placed in public folder}
    async getDataApi(url) {
      try {
        console.log("url: ", url);
        // Assuming url includes the correct path
        const markdownFile = await import(`!!raw-loader!/${url}.md`);
        this.markdownContent = markdownFile.default;
      } catch (error) {
        console.error("Error loading Markdown file:", error);
      }
    },

    // click to sidebar options & get HTML files data
    handleClick(event) {
      event.preventDefault();
      const currBtn = event.currentTarget;
      const currGitData = currBtn.getAttribute("data-github");
      if (currGitData) {
        this.currBtnData = currGitData;
        this.clearBgcolor();
        currBtn.setAttribute("style", "background-color: #e1ff9c;");
        console.log("currBtnData: ", this.currBtnData);
        this.$router.push({
          path: `/document-en${currGitData}`
        });
      }
    },
    clearBgcolor() {
      const developerPageDiv = document.querySelector(".developer_page"); // 获取<div class="developer_page">元素
      if (developerPageDiv) {
        const anchorTags = developerPageDiv.getElementsByTagName("a"); // 获取<div class="developer_page">下的所有<a>标签
        for (let i = 0; i < anchorTags.length; i++) {
          let anchorTag = anchorTags[i];
          if (anchorTags[i].getAttribute("data-github")) {
            let computedStyle = getComputedStyle(anchorTag);
            anchorTag.style.backgroundColor = computedStyle.backgroundColor; // 将<a>标签的computed background-color应用到普通状态下的background-color
            anchorTag.style.removeProperty("background-color"); // 移除<a>标签的普通状态下的background-color
          }
        }
      }
    },
    async scrollToView() {
      const hash = this.$route.hash; // 获取URL中的#id部分
      if (hash) {
        const elementId = hash.slice(1); // 去掉#，得到元素的ID
        const element = document.querySelector(
          `[data-v-md-heading="${elementId}"]`
        );
        if (element) {
          element.scrollIntoView({ behavior: "smooth" }); // 平滑滚动到元素
        }
      }
    },
    docSearchFun() {
      const sidebar_options_wrap = document.querySelector(
        ".sidebar_options_wrap"
      );
      const sidebar_accordion_items =
        sidebar_options_wrap.querySelectorAll(".accordion-item");
      const sidebar_accordion_ul = sidebar_options_wrap.querySelectorAll("ul");
      const sidebar_all_options = sidebar_options_wrap.querySelectorAll("li");
      let docSearchVal = this.docSearchVal;
      docSearchVal = docSearchVal.toLowerCase();

      let optionTextNode, optionText, anchor;
      sidebar_all_options.forEach((option) => {
        if (option.querySelector(".text b")) {
          optionText = option
            .querySelector(".text b")
            .textContent.toLowerCase();
          optionTextNode = optionText;
        } else {
          anchor = option.querySelector("a").textContent.toLowerCase();
          optionTextNode = anchor;
        }
        // check list item's inner text with input's value
        if (optionTextNode.indexOf(docSearchVal) != -1) {
          option.classList.add("show");
          option.classList.remove("hide");
        } else {
          option.classList.add("hide");
          option.classList.remove("show");
        }
      });

      // hide sidebar_accordion_item if all options are hide
      sidebar_accordion_ul.forEach((ul) => {
        const liAreFound = ul.querySelectorAll("li.show");
        if (!liAreFound.length) {
          ul.closest(".accordion-item").classList.add("hide");
          ul.closest(".accordion-item").classList.remove("show");
        } else {
          ul.closest(".accordion-item").classList.add("show");
          ul.closest(".accordion-item").classList.remove("hide");
        }
      });

      // show error if search not found
      let allItemsHide = true;
      sidebar_accordion_items.forEach((item) => {
        if (item.classList.contains("show")) {
          allItemsHide = false;
          return;
        }
      });
      this.resultNotFound = allItemsHide;
    }
  },

  mounted() {
    const sidebarOptions = document.querySelectorAll(
      ".developer_page .sidebar_options_wrap a"
    );
    sidebarOptions.forEach((option) => {
      option.addEventListener("click", this.handleClick);
    });
  }
};
</script>
