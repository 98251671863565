<template>

  <main>

    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng">NXCLOUD PRIVACY POLICY</h1>
            <p class="terms-desc term-days">Last updated: 2024-02-19</p>
            <!-- <p class="terms-desc term-days">
              Effective date:February 19th, 2024
            </p> -->

            <!-- <h2 class="terms-list-hdng privacy-hdng">Introduction</h2> -->
            <p class="terms-desc">
              We collect information regarding your use and purchase of our Services and your interactions with NXCLOUD. You also provide information when you make an enquiry on our website or register to use our Services. Examples include your name and contact details. We know your privacy is important to you, and we want you to know its important to us too. This Privacy Policy is meant to explain our practices when it comes to your use of our products, services, and websites. We encourage you to take the time to review it carefully, to understand what information we collect, why we collect it, and what we do with it. Below, we cover the following topics:
            </p>
            <p class="terms-desc">
              <ul class="article no-list-style">
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 1: Who we are
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 2: What type of personal information we collect
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 3: Why we collect personal information and how we use it
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 4: Who we share personal information with and why
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 5: How we protect personal information
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 6: Where we store personal information and international transfers
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 7: How long we keep personal information
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 8: How to access and control your personal information
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 9: Cookies
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 10: Dispute resolution
              </li>
              <li>
                <img class="li-img" src="@/assets/images/arrow.svg" />
                Section 11: How to contact us
              </li>
              </ul>
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 1: WHO WE ARE</h2>
            <p class="terms-desc">
              Welcome, and thank you for your interest in the online services collectively known as NXCLOUD, and any related websites, networks, applications and other services and related documentation provided by NXCLOUD (collectively, the "Services"）. NXCLOUD ASIA PTE. LTD. including its corporate affiliates, (“NXCLOUD,”“we,”“our”） is a global provider of cloud communications that offers various services.we offer a complete and fully-configured unified communications
              solution for the delivery of end-to-end communications.We also provide our communications services in programmable modules delivered via APIs and SDKs, enabling our customers to incorporate these services into their own applications to serve their own customers.
            </p>
            <p class="terms-desc">
              This Privacy Policy applies to our customers, prospective customers, our customerscustomers (both direct and indirect), job applicants, current and former employees, contractors, visitors to our company websites,and recipients of our e-mail communications.
            </p>
            <p class="terms-desc">
              When you use our websites or our services, you acknowledge and understand that NXCLOUD will collect, use, and share your information, as we describe in this Privacy Policy.
            </p>
            <p class="terms-desc">
              <strong>If you do not agree with this privacy policy, you must not use the Services.</strong>
            </p>
            <p class="terms-desc">
              This Privacy Policy will only apply to you if the Services are made available to you by the relevant NXCLOUD entity as set out in the Terms of Service.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION  2:  WHAT TYPE  OF PERSONAL INFORMATION  WE COLLECT</h2>
            <p class="terms-desc">
              We collect your information in the following ways:
            </p>
            <p class="terms-desc">
              <strong>Information you provide directly to us. </strong> you subscribe to our service, sign up for our services, request us to contact you, or through other interactions with us (or for business customer, act as the company's business representative to register for Services), we may ask you for certain personal information that identifies or is linked or reasonably linkable to you (“Personal Data” ,）such as your name, postal address, e-mail address, telephone number, company name, job title, or payment information, as well as non-Personal Data. For instance, and without limitation:
              <ul class="article article-Registration">
                <li>
                  When you request support from us, we may also collect information from you, including Personal Data, such as contact information, documentation, screenshots, or other information you or we may believe is helpful to solving the issue.
                </li>
                <li>
                  When you speak with our customer service or sales on the phone, your calls may be recorded and/or monitored for quality assurance, training and research purposes.
                </li>
                <li>
                  In certain circumstances and depending on the country where you reside, we may collect your Social Security number from you for a limited purpose, such as for tax reporting relating to a payment for a customer referral or to facilitate an international money transfer.
                </li>
              </ul>
            </p>
            <p class="terms-desc">
              <strong>Information we collect automatically when you visit our websites. </strong>, our vendors and
              third-party services, such as our advertising and business providers, collect information about your visits to our websites and your interactions with our ads or content, together with information such as your IP address, location, cookies, and other tracking technologies (e.g., web beacons, device identifiers, pixels, and cross-device tracking). For more information, please see our 
              <router-link
                :to="{
                  path: '/cookie-policy',
                }"
              ><a href="javascript:;">Cookies Policy</a>
              </router-link>.
            </p>
            <p class="terms-desc">
              <strong>Information we get from third parties.</strong> Third party sources of information include, without limitation:
              <ul class="article article-Registration">
                <li>
                  <strong>Government websites or credit rating agencies.</strong> We may obtain your credit information from government websites or credit rating agencies in order to control our own credit risk in on boarding new customers
                </li>
                <li>
                  <strong>Website or social media platform .</strong> We also obtain where permitted by law contact information and other marketing lead information from third parties, websites or social media platforms and may combine it with information we have, for instance, to contact you or direct NXCLOUD marketing information to you.
                </li>
              </ul>
            </p>
            <p class="terms-desc">
              <strong>Information about our customers users.</strong> Our customers and other third parties may also provide us with Personal Data and other information about our customers users and others. For example, we 
              may receive Personal Data and other information from our customers, message senders, mobile network operators, databases with information relevant to mobile telephone numbers submitted to our services, and other third parties. This information may include, without limitation, telephone numbers, telephone numbers validity, corresponding device status (e.g., whether or not it is currently available for messaging), roaming status, carrier and country of location,for instance, to help us provide our services.
            </p>
            <p class="terms-desc">
              <strong>Information collected in connection with your use of services delivered via our platform.</strong> We and our service providers may collect Personal Data and other information in connection with your use of communications services delivered via our platform, including, without limitation:
              <ul class="article article-Registration">
                <li>
                  Communications usage information. This includes information about your communications delivered via our platform such as the time and duration of usage, source and destination identifiers, from/to phone numbers, completion status, location, IP address, and amount of usage.
                </li>
                <li>
                Communications content. To enable you to send and receive communications via our platform, we need to be able to handle the content of the messages, calls, and other communications channels used by you. This also includes, for example, voicemails, messages and call recordings recorded via our services.
                </li>
                <li>
                  Device information. Where you have installed our software on your device, we collect device-specific information from you. This includes, for example, your hardware model, operating system version, firmware, network configuration, device identifier, IP address, device performance and mobile or wireless network information. We use the device information we collect in order to deliver and improve our services. Device information is also collected when you visit our websites, as more fully detailed above.
                </li>
              </ul>
            </p>
            <p class="terms-desc">
              <strong>Mobile device camera, microphone and photos. </strong> If you use our mobile apps, we may request your permission to access the camera, microphone, and photos on your mobile device, to make and receive voice and video calls and messages and to send photos to others. You do not have to allow us to access these functions of your device, but if you do not, certain features of our mobile apps may not be available to you. You may at any time opt out from allowing this access via the privacy settings on your device.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 3: WHY WE COLLECT PERSONAL INFORMATION AND HOW WE USE IT</h2>
            <p class="terms-desc">
              How we use the information we collect depends on the nature of the data, which of our services you use, how you use them, and specific preferences you may have communicated to us. We reserve the right to collect,	share and otherwise use non-Personal Data for any purpose except where limited by applicable law, and to create non-Personal Data from Personal Data in order to do so. We list below the specific purposes for which we collect, use and otherwise process your Personal Data:
              <ul class="article article-Registration">
                <li>
                  <strong>To deliver our services.</strong> We use your Personal Data and other information as necessary to perform our obligations in delivering our services to our customers. This includes delivering your communications to the intended end user, processing transactions with you (such as billing), authenticating you when you log into our platform, providing customer support, and operating and maintaining our services. We also need your information to communicate with you about the services, including registration confirmations, purchase confirmations, expiration or renewal reminders, responding to your requests, and sending you notices, updates, security alerts, administrative messages, and other communications necessary to usage of the services.
                </li>
                <li>
                  <strong>To carry out core activities relating to our services.</strong> To effectively deliver our services to you, we use your Personal Data and other information to engage in important supporting activities such as:
                  <ul class="article no-list-style">
                    <li>
                      a.	billing and collections, including maintenance of records in the event of a subsequent billing dispute;
                    </li>
                    <li>
                      b.	preventing fraud, violations of our acceptable use policies, and unlawful activities;
                    </li>
                    <li>
                      c.	troubleshooting, quality control, and analytic; and
                    </li>
                    <li>
                      d.	monitoring the performance of our systems and platform.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>To improve our services and develop new services.</strong> We use your data in the provision of the services.	We also use data we collect in existing services to improve and develop new services.
                </li>
                <li>
                  <strong>To market, promote, and drive engagement of our products and services.</strong> We use Personal Data and other information about you to send promotional communications, such as e-mail messages, text messages, phone calls, faxes and postal mail, and for marketing when we have an adequate legal ground to do so. When prior opt-in consent is required by applicable laws, we will obtain such consent. We do not send marketing communication to our customers customers who are end users. Based on information we collect about you, we may decide whether and how to promote certain of our products or services to you over others.
                </li>
                <li>
                  <strong>To comply  with  legal  requirements.</strong> Applicable  laws  or  regulations may  require  our processing of your Personal Data and other information, such as laws mandating retention of communications data, including disclosures required by law or legal process.
                </li>
                <li>
                  <strong>To protect our legitimate business interests and legal rights.</strong> Where we believe it is necessary to protect our legal rights and interests, or the	interests of others, we use Personal Data and other information about you in connection with legal claims, compliance, regulatory, and audit functions.
                </li>
                <li>
                  <strong>According to your explicit consent.</strong> If we wish to use your Personal Data for certain purposes which require consent under applicable law, we will first seek out and obtain your consent.
                </li>
              </ul>
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 4: WHO WE SHARE PERSONAL INFORMATION WITH AND WHY</h2>
            <p class="terms-desc">
              We reserve the right to collect, share and otherwise disclose non-Personal Data for any purpose except where limited by applicable law, and to create non-Personal Data from Personal Data in order to do so. We may share your Personal Data and other information as detailed below:
            </p>
            <p class="terms-desc">
              <ul class="article article-Registration">
                <li>
                  <strong>Third-party service providers that help us to deliver the services and allow us to operate our businesses.</strong>
                  <ul class="article no-list-style">
                    <li>
                      a.	Communications providers. As the provider of a communications platform, we share the data we collect from you with communications providers (including operator and
over-the-top communications service providers) as necessary in order to provide you with the services. These are the telecommunications companies, for instance, who we need to ensure your calls, messages and other communications reach the people you want to contact.
                    </li>
                    <li>
                      b.	Payment gateways: In the provision of conversational commerce services, we use payment gateways as necessary in order to provide you with the services. These are payment gateways companies that ensure the payment is completed in relation to conversational commerce services.
                    </li>
                  </ul>
                </li>
                <li>
                  Third party services. Our services and websites may include links or other tools (e.g.SDKs, etc.) that direct you to other websites or services whose privacy practices may differ from ours. If you submit Personal Data or other information to any of those third parties, your information is governed by their privacy policies, not this one. We encourage you to carefully read the privacy policy of any third party with which you interact. NXCLOUD disclaims all liability for personal information you provide to any Third Party Websites.
                </li>
                 <li>
                   Compliance with law enforcement requests and applicable laws; enforcement of our rights. We may disclose Personal Data and other information as required by applicable law, regulation, legal process or government request; to protect NXCLOUD, our services, our customers or the public from harm or illegal activities; and to enforce our agreements, policies and service terms. If NXCLOUD receives a civil or criminal subpoena, warrant, or other official and written request that is legally binding (“Request”） by a public authority for disclosure of personal data, NXCLOUD will promptly notify the customer to the extent NXCLOUD is not precluded from doing so by the Request. In the event that the
information must be provided, NXCLOUD will (a) ensure that the disclosed Personal Data is the minimum required to satisfy the Request; and (b) take all commercially reasonable steps to ensure that such personal information is afforded confidential treatment by the authorities.
                </li>
                 <li>
                   With your explicit consent. We share Personal Data and other information about you with third parties when you give us consent to do so. For example, if you are a business customer, and have requested this, your business name and phone number may be included in public directories.
                </li>
                 <li>
                   NXCLOUD; business transactions. We share your Personal Data and other information with and among our corporate affiliates, for instance in order to operate and improve the services we provide to you; and we may share your information in connection with a sale, merger, financing, liquidation, or reorganization of our business or assets.
                </li>
                 <li>
                   Credit control. When permitted by applicable laws, we obtain credit reports on new customers in order to evaluate customers for the risk of non-payment. In the event of non- or late payment, we may furnish your name, address and other details to credit bureaus and other consumer reporting agencies. They may include that information in preparing credit reports and use that information in calculating credit scores provided to their customers.
                </li>
              </ul>
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 5: HOW WE PROTECT PERSONAL INFORMATION</h2>
            <p class="terms-desc">
              NXCLOUD has implemented administrative, physical, and technical safeguards to help protect the Personal Data that we transmit and maintain. Those measures are heightened for sensitive data such as Social Security numbers and payment account numbers. However, no system or service can provide a 100% guarantee of security, especially a service that relies upon the public internet. Therefore, you acknowledge the risk that third parties may gain unauthorized access to your Personal Data and other information. Keep your account password secret and please let us know immediately if you think your password was compromised. Remember, you are responsible for any activity under your account using your account password or other credentials.
            </p>
            <p class="terms-desc">
              No  mobile  information  will  be  shared  with  third  parties/affiliates  for  marketing/promotional purposes. However, NXCLOUD will exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION  6:  WHERE  WE  STORE  PERSONAL  INFORMATION AND INTERNATIONAL TRACSFERS</h2>
            <p class="terms-desc">
              Personal information held by NXCLOUD is stored on and processed on Cloud servers situated in the Singapore  ， Hong Kong,China and in other jurisdictions. We and/or our service providers also process  data  in  some  other  countries  for  customer  care,  account  management  and  service provisioning.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 7: HOW LONG WE KEEP PERSON INFORMATION</h2>
            <p class="terms-desc">
              NXCLOUD will store your information as long as needed to provide you with our services and to operate our business. If you ask NXCLOUD to delete specific personal data from your Account Data ( You can also delete personal information by contacting customer service), we will honor this request unless deleting that information prevents us from carrying out necessary business functions, such as billing for our services, calculating taxes, or conducting required audits. We may also retain aggregate, de-identified or anonymized information beyond this time for, among other uses, research purposes  and  to  help  us develop  and  improve our services. We will take measures  to  prevent reidentification  de-identified  information  retained  or  used  for  these  purposes  consistent  with 
              applicable law, and will not make efforts (or authorise others to make efforts) to re-identify it. 
            </p>
            <p class="terms-desc">
              Customer account information. We store your account information for as long as your account is
              active and a reasonable period thereafter in case you decide to re-activate the services, or for so long as we need to retain it for legitimate purposes, such as comply with our legal obligations, resolve disputes, enforce our agreements, support business operations, and continue to develop and improve our services.
            </p>
            <p class="terms-desc">
              Marketing information, cookies and web beacons. If you receive marketing e-mails from us, we retain information about your e-mail marketing preferences for a reasonable period of time from the date you last expressed interest in our services, such as when you last opened an e-mail from us or visited our websites.	We also retain information regarding your giving or removal of consent as business records and to honour consent choices. We retain information derived from cookies and other tracking technologies for a reasonable period of time from the date such information was created.
            </p>
            <p class="terms-desc">
              Notwithstanding with the above and the fact that we will generally store your personal data until it is no longer necessary to provide the services or otherwise relevant for the purposes for which it was collected, please note that we may also retain your information for a longer period of time to defend and/or protect our legal or business interests during the applicable statute of limitation period or as otherwise required by law, court order, or other applicable regulation.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION   8:   HOW   TO   ACCESS   AND   CONTROLL   YOUR PERSONAL INFORMATION</h2>
            <p class="terms-desc">
              <strong>Your choices.</strong> To request access, correct and deletion of your NXCLOUD account, please contact our support  team  via  e-mail  <a href="javascript:;">compliance@nxcloud.com</a>.  If  you  believe  there  is  any  other  personal information we process about you, or you are unable to correct or delete inaccurate information, please contact our support team.
              <br/>
              Some of the user's information may be inaccessible, modified and deleted for legitimate reasons such as legal and regulatory requirements and to maintain information security.
            </p>
            <p class="terms-desc">
              <strong>Obtaining a copy of personal data. </strong> The user has the right to obtain a copy of the user's personal information   from  <a href="javascript:;">compliance@nxcloud.com</a>.  Where  technically   feasible,   e.g.   data  interface matching.
            </p>
            <p class="terms-desc">
              <strong>Responding  to  User  requests  as  described  above. </strong> For  security  purposes,  we  may  require verification of the User's identity before processing the User's request. The User may be required to provide a written request or otherwise prove the User's identity. We will respond to all user requests under this section within 15 days of receiving the user's request and verifying the user's identity.
            </p>
            <p class="terms-desc">
              <strong>Report complaints to us. </strong> If the user believes that the user's information may have been violated, the 
              user can click on the "online customer service" <a href="javascript:;">compliance@nxcloud.com</a>. We will review the issue as soon as possible and respond in a timely manner after verifying the user's identity, usually within a few days.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 9: COOKIES</h2>
            <p class="terms-desc">
              A cookie is a piece of data contained in a very small text file that is stored in your browser or elsewhere on your hard drive. Cookies allow NXCLOUD to identify your device as you navigate our websites or your account. This makes navigating and interacting with our websites or your account more efficient, easy and meaningful for you.
              <br/>
              By themselves, cookies do not identify you specifically. Rather, they recognize your web browser. So, unless you identify yourself specifically to NXCLOUD, like signing into your account, we do not know who you are just because you visited our website.
            </p>
            <p class="terms-desc">
              NXCLOUD uses both session and persistent cookies. Session cookies are cookies that disappear from your computer or browser when you turn off your computer. Persistent cookies stay on your computer even after youve turned it off. Additionally, the cookies on our websites fall into three categories: (1) Required Cookies, (2) Functional Cookies, and (3) Advertising Cookies. To learn more about each category of cookie, you can visit our cookie consent tool by clicking on the "Cookie Preferences" link on the bottom right of the NXCLOUD website you are visiting.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 10: DISPUTE RESULATION</h2>
            <p class="terms-desc">
              This Privacy Policy and any dispute or claim arising out of or in connection with it or its subject matter  or  formation  (including  non-contractual  disputes  or  claims)  shall  be  governed  by  and construed and enforced in accordance with the laws of Singapore.
            </p>
            <p class="terms-desc">
              Any dispute first arising in relation to the Privacy Policy and all claims and matters will be resolved primarily  through  negotiation  between  the  Parties.  If  the  resolution  is  not  reached  through negotiation after thirty (30) working days from the date of commencing amicable dispute resolution, one party may  submit the dispute to be settled by  the The Singapore  International Arbitration Centre(SIAC).The proceedings shall be held in English. The arbitrators decision shall be final and binding.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 11: HOW TO CONTACT US</h2>
            <p class="terms-desc">
              If you have any questions, comments or suggestions regarding this privacy policy, please contact us via email: <a href="javascript:;">compliance@nxcloud.com</a>,telephone: <a href="javascript:;">400-7800-900</a>, and we will respond to your request within 15 days.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
    export default {
        name: 'PrivacyPolicy'
    }
</script>
<style scoped>
.article-Registration {
  padding-left: 24px;
}
.article {
  list-style-type: inherit;
  margin-left: 8px;
}
.no-list-style {
  list-style-type: none;
}
.terms .article li {
  font-weight: 400;
}
.li-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>

  