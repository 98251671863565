<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="home_slider swiperSlider">
              <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="50"
                :autoplay="{ delay: 5000, disableOnInteraction: false }"
                :loop="true"
                navigation
                :pagination="{ clickable: true }"
                @swiper="homeBannerSwiper"
                @slideChange="onHomeBannerSlideChange"
              >
              <!-- MWC活动已结束，需同步下架banner图，因banner只剩一个，隐藏切换按钮 -->
                <!-- <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-6 d-flex">
                      <div class="inner">
                        <h1 class="hdng">
                          2024 <br />
                          MWC Barcelona
                        </h1>
                        <h2>Let's meet in Barcelona!</h2>
                        <ul>
                          <li>
                            <div class="icon_item">
                              <i class="icon">
                                <img
                                  src="../../assets/images/calender_icon_white.svg"
                                  alt="calender_icon"
                                />
                              </i>
                              <span class="text">26 - 29 Feb 2024</span>
                            </div>
                          </li>
                          <li>
                            <div class="icon_item">
                              <i class="icon">
                                <img
                                  src="../../assets/images/location_icon_white.svg"
                                  alt="location_icon"
                                />
                              </i>
                              <span class="text"
                                >Booth 6C58 Fira Gran Via Barcelona
                              </span>
                            </div>
                          </li>
                        </ul>
                        <a
                          href="mailto:info@nxcloud.com"
                          class="cmn_btn light"
                          style="text-transform: initial"
                        >
                          Schedule a Meeting
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                              stroke="#85C100"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <img
                        src="../../assets/images/home_slide_img.png"
                        alt=""
                      />
                    </div>
                  </div>
                </swiper-slide> -->
                <swiper-slide class="item">
                  <div class="row">
                    <div class="col-12 col-md-7 d-flex">
                      <div class="inner">
                        <h1 class="hdng">
                          Global Reach, <br />
                          Seamless Connections, <br />
                          Your Cloud <br />
                          Communications Partner
                        </h1>
                        <p class="cntnts">
                          <strong>
                            Drive international success for your business
                            through our trusted industry leading partners.
                          </strong>
                        </p>
                        <router-link to="/sign-up" class="cmn_btn light">
                          Start Now
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 18 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                              stroke="#85C100"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <img src="../../assets/images/kv_visual_01.png" alt="" />
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="customer-section desktop">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">
              Connecting the world all <br />
              in one just for you
            </h3>
          </div>
        </div>

        <div class="row customerBoxes">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 cs_flex">
            <div class="icon_inner inner shift_right">
              <img src="../../assets/images/icon_01.svg" alt="" class="icon" />
              <h4 class="subHdng">Message</h4>
              <p class="desc">
                Effortlessly send and receive messages through various channels.
              </p>
              <router-link to="/product-message">
                Learn More
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner">
              <img src="../../assets/images/icon_02.svg" alt="" class="icon" />
              <h4 class="subHdng">Voice</h4>
              <p class="desc">Seamlessly send and receive worldwide calls.</p>
              <router-link to="/product-voice">
                Learn More
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner shift_right">
              <img
                src="../../assets/images/conversation.svg"
                alt=""
                class="icon"
              />
              <h4 class="subHdng">Conversation</h4>
              <p class="desc">
                Safe and uninterrupted two-way communication anytime, anywhere.
              </p>
              <router-link to="/product-conversation">
                Learn More
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="inner d-flex justify-content-center mt0">
              <img
                src="../../assets/images/customer.png"
                alt=""
                class="heroImg"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 cs_flex">
            <div class="icon_inner inner shift_left">
              <img src="../../assets/images/icon_04.svg" alt="" class="icon" />
              <h4 class="subHdng">Fraud Prevention</h4>
              <p class="desc">
                Protect your business and customers by preventing malicious
                activities with our emails.
              </p>
              <router-link to="/product-fraud-prevention">
                Learn More
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner">
              <img src="../../assets/images/icon_05.svg" alt="" class="icon" />
              <h4 class="subHdng">Global Number</h4>
              <p class="desc">
                Establish global presence for your business with our
                international virtual numbers and SMS.
              </p>
              <router-link to="/product-global-number">
                Learn More
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>

            <div class="icon_inner inner shift_left">
              <img src="../../assets/images/icon_06.svg" alt="" class="icon" />
              <h4 class="subHdng">Customer Engagement</h4>
              <p class="desc">
                Supercharge your customer care support across omnichannels with
                our unified dashboard.
              </p>
              <router-link to="/product-customer-engagement">
                Learn More
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="customer-section mobile">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">
              Connecting the world all <br />
              in one just for you
            </h3>
          </div>
        </div>

        <div class="row customerBoxes">
          <div class="col-12 cs_flex">
            <div class="customerSlider swiperSlider numberNav">
              <swiper
                :modules="modules"
                :slides-per-view="1"
                :autoplay="{ delay: 5000, disableOnInteraction: false }"
                :loop="true"
                navigation
                :pagination="{ clickable: true }"
                @swiper="customerSliderSwiper"
                @slideChange="customerSliderSlideChange"
              >
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_right">
                    <img
                      src="../../assets/images/icon_01.svg"
                      alt=""
                      class="icon"
                    />
                    <h4 class="subHdng">Message</h4>
                    <p class="desc">
                      Effortlessly send and receive messages through various
                      channels.
                    </p>
                    <router-link to="/product-message">
                      Learn More
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner">
                    <img
                      src="../../assets/images/icon_02.svg"
                      alt=""
                      class="icon"
                    />
                    <h4 class="subHdng">Voice</h4>
                    <p class="desc">
                      Seamlessly send and receive worldwide calls.
                    </p>
                    <router-link to="/product-voice">
                      Learn More
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_right">
                    <img
                      src="../../assets/images/conversation.svg"
                      alt=""
                      class="icon"
                    />
                    <h4 class="subHdng">Conversation</h4>
                    <p class="desc">
                      Safe and uninterrupted two-way communication anytime,
                      anywhere.
                    </p>
                    <router-link to="/product-conversation">
                      Learn More
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_left">
                    <img
                      src="../../assets/images/icon_04.svg"
                      alt=""
                      class="icon"
                    />
                    <h4 class="subHdng">Fraud Prevention</h4>
                    <p class="desc">
                      Protect your business and customers by preventing
                      malicious activities with our emails.
                    </p>
                    <router-link to="/product-fraud-prevention">
                      Learn More
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner">
                    <img
                      src="../../assets/images/icon_05.svg"
                      alt=""
                      class="icon"
                    />
                    <h4 class="subHdng">Global Number</h4>
                    <p class="desc">
                      Establish global presence for your business with our
                      international virtual numbers and SMS.
                    </p>
                    <router-link to="/product-global-number">
                      Learn More
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
                <swiper-slide class="item">
                  <div class="icon_inner inner shift_left">
                    <img
                      src="../../assets/images/icon_06.svg"
                      alt=""
                      class="icon"
                    />
                    <h4 class="subHdng">Customer Engagement</h4>
                    <p class="desc">
                      Supercharge your customer care support across omnichannels
                      with our unified dashboard.
                    </p>
                    <router-link to="/product-customer-engagement">
                      Learn More
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#000000"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="custom_buttons">
                <button class="prev" @click="leftSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
                <span class="count"
                  >{{ currentSlide }} / {{ slideLength }}</span
                >
                <button class="next" @click="rightSlide">
                  <img src="../../assets/images/chevron.png" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="inner d-flex justify-content-center mt0">
              <img
                src="../../assets/images/customer.png"
                alt=""
                class="heroImg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-section">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Our Esteemed Partners</h3>
          </div>

          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap">
              <img
                src="../../assets/images/whatsapp_white.svg"
                class="img-fluid"
                alt="whatsap"
              />
              <h6 class="archived-icons-desc">WhatsApp Business Partner</h6>
            </div>
          </div>
          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap">
              <img
                src="../../assets/images/gsma_white.svg"
                class="img-fluid"
                alt="gsma"
              />
              <h6 class="archived-icons-desc">GSMA Member</h6>
            </div>
          </div>
          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap last-archived-details">
              <img
                src="../../assets/images/viber_white.svg"
                class="img-fluid"
                alt="call"
              />
              <h6 class="archived-icons-desc">Viber Business Partner</h6>
            </div>
          </div>

          <div class="col-6 col-xl-3 col-lg-3">
            <div class="archived-details-wrap last-archived-details">
              <img
                src="../../assets/images/zalo_white.svg"
                class="img-fluid"
                alt="call"
              />
              <h6 class="archived-icons-desc">Zalo Business Partner</h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section desktop">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">What others say about NXCLOUD</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/coffee_order.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased conversion rates by 300%
                  </h4>
                  <p class="archived-card-desc">
                    A well-known international coffee chain that expanded to
                    Southeast Asia harnessed our solutions to provide a new
                    sales line on WhatsApp to effectively reach its new
                    audience.
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">2x</h5>
                      <p class="card-number-desc">
                        increase in customer retention rate
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/customer_satisfaction.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Customer satisfaction rate increased by 54%
                  </h4>
                  <p class="archived-card-desc">
                    A global automobile brand harnessed NXLink for its one-stop
                    customer relationship management capabilities, effectively
                    reaching customers overseas and providing a comprehensive
                    customer service experience.
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">33%</h5>
                      <p class="card-number-desc">Growth in Sales</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/sms_delivery.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    SMS arrival rate as high as 95.2%
                  </h4>
                  <p class="archived-card-desc">
                    A popular gaming platform available worldwide was able to
                    ensure secure user identification when logging in or
                    changing passwords by sending a code delivered via SMS or
                    voice call to prevent malicious behavior.
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">21%</h5>
                      <p class="card-number-desc">
                        Reduction in Operating Costs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section mobile">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">What others say about NXCLOUD</h3>
          </div>

          <div class="otherSayAboutUs swiperSlider">
            <swiper
              :modules="modules"
              :slides-per-view="1"
              :space-between="20"
              :equal-height="true"
              :autoplay="{ delay: 5000, disableOnInteraction: true }"
              :loop="true"
              navigation
              :pagination="{ clickable: true }"
              @swiper="otherSayAboutUsSwiper"
              @slideChange="otherSayAboutUsSlideChange"
            >
              <swiper-slide class="item">
                <div class="col-xl-4 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="archived-card-wrap">
                        <img
                          src="../../assets/images/coffee_order.svg"
                          class="img-fluid"
                          alt="card"
                        />
                        <h4 class="archived-card-hdng">
                          Increased conversion rates by 300%
                        </h4>
                        <p class="archived-card-desc">
                          A well-known international coffee chain that expanded
                          to Southeast Asia harnessed our solutions to provide a
                          new sales line on WhatsApp to effectively reach its
                          new audience.
                        </p>

                        <div class="archived-card-numbering-wrap">
                          <div class="archived-card-number">
                            <h5 class="card-number-hdng">2x</h5>
                            <p class="card-number-desc">
                              increase in customer retention rate
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="col-xl-4 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="archived-card-wrap">
                        <img
                          src="../../assets/images/customer_satisfaction.svg"
                          class="img-fluid"
                          alt="slack"
                        />
                        <h4 class="archived-card-hdng">
                          Customer satisfaction rate increased by 54%
                        </h4>
                        <p class="archived-card-desc">
                          A global automobile brand harnessed NXLink for its
                          one-stop customer relationship management
                          capabilities, effectively reaching customers overseas
                          and providing a comprehensive customer service
                          experience.
                        </p>

                        <div class="archived-card-numbering-wrap">
                          <div class="archived-card-number">
                            <h5 class="card-number-hdng">33%</h5>
                            <p class="card-number-desc">Growth in Sales</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide class="item">
                <div class="col-xl-4 col-lg-4 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="archived-card-wrap">
                        <img
                          src="../../assets/images/sms_delivery.svg"
                          class="img-fluid"
                          alt="hsbc"
                        />
                        <h4 class="archived-card-hdng">
                          SMS arrival rate as high as 95.2%
                        </h4>
                        <p class="archived-card-desc">
                          A popular gaming platform available worldwide was able
                          to ensure secure user identification when logging in
                          or changing passwords by sending a code delivered via
                          SMS or voice call to prevent malicious behavior.
                        </p>

                        <div class="archived-card-numbering-wrap">
                          <div class="archived-card-number">
                            <h5 class="card-number-hdng">21%</h5>
                            <p class="card-number-desc">
                              Reduction in Operating Costs
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="global-services-section">
      <div class="container">
        <div class="row row-global-services">
          <div class="col-md-5">
            <div class="global-services-figure">
              <img
                src="../../assets/images/Key-visual-2.png"
                class="img-fluid"
                alt="Key-visual-2"
              />
            </div>
          </div>

          <div class="col-md-7">
            <div class="global-services-wrap">
              <h3 class="archived-hdng Providing-global-hdng">
                Bring your business to a global scale across 185+ countries of
                coverage
              </h3>

              <div class="archived-card-numbering-wrap">
                <div class="archived-card-number global-card-service">
                  <h5 class="card-number-hdng">3.5B+</h5>
                  <p class="card-number-desc global-services-desc">
                    <strong>
                      Messages <br />
                      Transacted Annually
                    </strong>
                  </p>
                </div>

                <div class="archived-card-number global-card-service">
                  <h5 class="card-number-hdng">800M+</h5>
                  <p class="card-number-desc global-services-desc">
                    <strong>
                      Total Annual <br />
                      Call Minutes
                    </strong>
                  </p>
                </div>

                <div class="archived-card-number global-card-service">
                  <h5 class="card-number-hdng">10,000+</h5>
                  <p class="card-number-desc global-services-desc">
                    <strong>
                      Served <br />
                      Customers</strong
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

import { ref } from "vue";

// Import Swiper styles
export default {
  name: "HomePage",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const currentSlide = ref(1); // Initialize currentSlide with initial value 1
    const slideLength = ref(1); // Initialize slideLength with initial value 1
    const homeBannerSwiper = () => {};
    const onHomeBannerSlideChange = () => {};

    // For Number pagination
    const customerSliderSwiper = (swiper) => {
      slideLength.value = swiper.slides.length;
      swiper.on("slideChange", () => {
        currentSlide.value = swiper.realIndex + 1;
      });
    };

    const customerSliderSlideChange = () => {};

    const otherSayAboutUsSwiper = () => {};
    const otherSayAboutUsSlideChange = () => {};
    return {
      currentSlide,
      slideLength,
      homeBannerSwiper,
      onHomeBannerSlideChange,
      customerSliderSwiper,
      customerSliderSlideChange,
      otherSayAboutUsSwiper,
      otherSayAboutUsSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
  methods: {
    leftSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const prevButton = currSlider.querySelector(".swiper-button-prev");
      if (prevButton) {
        prevButton.click();
      } else {
        console.error("Previous button not found");
      }
    },
    rightSlide(event) {
      const currSlider = event.target.closest(".swiperSlider");
      const nextButton = currSlider.querySelector(".swiper-button-next");
      if (nextButton) {
        nextButton.click();
      } else {
        console.error("Next button not found");
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 隐藏banner切换按钮----start
:deep(.swiper-pagination) {
  display: none !important;
}
:deep(.custom_buttons button) {
  display: none;
}
// -----end
</style>