<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">在线教育解决方案</h1>
                  <p class="cntnt">为在线教育平台提供一站式通信能力</p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/industry/education_banner.png"
                  alt="education_banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>为在线教育平台定制完整的 <br />行业解决方案</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/empowering_online_education.svg"
                    alt="benifit_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>降低运营成本</h3>
                    <p>
                      以较低的成本让更多海外用户了解到最新服务或课程，实现多样化渠道精准触达，一站式解决平台各类通信需求，降低推广和运营成本。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/increase_user_acquisition.svg"
                    alt="benifit_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>提升获客效率</h3>
                    <p>
                      通过文本、语音、视频等方式，帮助平台推广在线课程，替代现有人工电话销售工作，补足多种营销方式，提升营销效果。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/increase_user_loyalty.svg"
                    alt="benifit_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>增加用户粘性</h3>
                    <p>
                      及时通知课程信息、给予会员关怀，以持续不断的优质服务提升用户服务感知和体验，增强用户粘性，促使用户留存。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_4_1 pt_100 pb_100">
      <div class="container_1700">
        <div class="sec_heading text-center">
          <h2>客户全旅程服务</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_service.svg"
                    alt="registration_login"
                  />
                </div>
                <div class="text_wrap">
                  <h3>1. 客户服务</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'AICC' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>

                    <router-link
                      to="/zh-product-global-number"
                      class="tag icon_item"
                    >
                      <i class="icon">
                        <img
                          src="../../assets/images/customer_journey_section/global_number.svg"
                          alt="icon"
                        />
                      </i>
                      <span class="text">全球虚拟号码</span>
                    </router-link>

                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'zaloBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/zalo_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/registraion_verification.svg"
                    alt="registraion_verification"
                  />
                </div>
                <div class="text_wrap">
                  <h3>2. 注册验证</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'smsOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="smsOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/sms_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">短信验证码</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-voice',
                        query: { id: 'voiceOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="voiceOtp"
                        data-url="../zh-product-voice"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/voice_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">语音验证码</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp验证</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber验证</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-fraud-prevention',
                        query: { id: 'OTA' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="OTA"
                        data-url="../zh-product-fraud-prevention"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_12.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">一键登录</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/course_promotion.svg"
                    alt="course_promotion"
                  />
                </div>
                <div class="text_wrap">
                  <h3>3. 课程推广</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'marketingOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="marketingOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_02.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">营销短信</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="sms otp"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'zaloBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="zaloBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/zalo_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'AICC' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link
                      to="/zh-product-global-number"
                      class="tag icon_item"
                    >
                      <i class="icon">
                        <img
                          src="../../assets/images/customer_journey_section/global_number.svg"
                          alt="icon"
                        />
                      </i>
                      <span class="text">全球虚拟号码</span>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'NXLink' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="NXLink"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_14.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">NXLink</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="content_box step step_4">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/course_notification.svg"
                    alt="course_notification"
                  />
                </div>
                <div class="text_wrap">
                  <h3>4. 课程通知</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'notificationOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="notificationOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/notification_sms.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">通知短信</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-voice',
                        query: { id: 'voiceNotification' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="voiceNotification"
                        data-url="../zh-product-voice"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/voice_notification.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">语音通知</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'AICC' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      to="/zh-product-global-number"
                      class="tag icon_item"
                    >
                      <i class="icon">
                        <img
                          src="../../assets/images/customer_journey_section/global_number.svg"
                          alt="icon"
                        />
                      </i>
                      <span class="text">全球虚拟号码</span>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey_new pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img
                  src="../../assets/images/industry_education.svg"
                  alt="industry_education"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>产品推荐</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/NXLing.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>NXLink</h3>
                  <p>
                    集成全球短信、语音、WhatsApp等通信能力，可便捷开展对话式客户服务、客户管理，构建企业私域流量，精准解决售后、复购问题。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/aicc-change.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>AICC</h3>
                  <p>
                    融合传统呼叫中心和AI智能、SaaS技术能力，为出海企业提供一种全方位、智能化客户服务方案。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Marketing-sms.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>营销短信</h3>
                  <p>
                    通过短信向消费者发送特价提示、优惠券、促销活动等营销类信息，可附带网站或平台短链接，是一种可实现精准获客的营销方式。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Sms-A2P.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>SMS A2P, P2A</h3>
                  <p>
                    提供海外本地固话以及移动号码，具备呼入与呼出的语音通信能力以及上下行短信通知能力，可在任何地方呼叫和接听各国电话。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success"
                >开始使用</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ZhIndustryEducation",
};
</script>
