import { createStore } from 'vuex'  
  
const store = createStore({  
  state() {  
    return {  
      fromChina: false,
      whereFrom: "",
    }  
  },  
  mutations: {  
    updateFromChina(state, fromChina) {  
      state.fromChina = fromChina;  
    }, 
    updateWhereFrom(state, whereFrom) {  
      state.whereFrom = whereFrom;  
    }, 
  },
})

export default store