<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">
                    Global Cloud Communication Innovator, always remembering our
                    values
                    <!-- Global Cloud Communication <br />Innovator -->
                  </h1>
                  <p class="cntnt">
                    At the core of NXCLOUD lies a steadfast commitment to our values, guiding us to uphold the highest standards and consistently exceed expectations in all our solutions and services.
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img src="../../assets/images/company_banner.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- company_desc -->
    <section class="company_desc pb_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Company Profile</h2>
          <p>
            Singapore-headquartered NXCLOUD offers communication solutions and
            services that simplify global customer engagement for businesses of
            all sizes. Our robust APIs, zero-code services and unified customer
            engagement platform streamline communications from messaging to
            voice calls, eliminating siloed channels, complex integration and
            limited reach. Serving over 10,000 clients across 185 countries, we
            have been instrumental in expanding our clients’ business presence
            globally, quickly and flexibly thus creating new opportunities and
            revenue growth.
          </p>
        </div>
        <div class="content_2">
          <h3>Core Values</h3>
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>Sincerity</h3>
                <div class="details">
                  <p>
                    Ground in integrity and transparency, we embrace trust and fairness in all our interactions, addressing challenges head-on with solution-oriented approaches.
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Equal</a>
                    </li>
                    <li>
                      <a href="#">Fair</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Simple</a>
                    </li>
                    <li>
                      <a href="#">Reliable</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>Professional</h3>
                <div class="details">
                  <p>
                    Prioritizing customer satisfaction, we are committed to driving continuous improvement in expertise and service delivery. With steadfast professionalism, we guarantee lasting value and excellence in all we do.
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Customer focus</a>
                    </li>
                    <li>
                      <a href="#">Professional</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Attitude</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>Perseverance</h3>
                <div class="details">
                  <p>
                    Amid adversity, we persevere with unwavering commitment to our goals, fueled by determination. Our resilience propels us beyond challenges towards success. 
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Consistent</a>
                    </li>
                    <li>
                      <a href="#">Resilent</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Entrepreneurial</a>
                    </li>
                    <li>
                      <a href="#">Long-term perspective</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>Collaboration</h3>
                <div class="details">
                  <p>
                    Collaborating with stakeholders, we nurture trust and unity to reach shared goals. Embracing diversity and cooperation, we foster inclusivity for collective growth.
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Common goals</a>
                    </li>
                    <li>
                      <a href="#">Trust</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Empathetic</a>
                    </li>
                    <li>
                      <a href="#">Cross functional</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>Responsibility</h3>
                <div class="details">
                  <p>
                    Diligence and accountability are our guiding principles in execution, ensuring swift error correction and full ownership of our actions. With an entrepreneurial mindset, we steadfastly pursue success.
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Practical</a>
                    </li>
                    <li>
                      <a href="#">Responsible</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Ownership</a>
                    </li>
                    <li>
                      <a href="#">Reflection</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="company_profile_card">
                <h3>Innovation</h3>
                <div class="details">
                  <p>
                    Fueled by curiosity, we are always exploring innovative ideas to enrich our offerings. Complacency has no place here - we champion technology to add value and drive growth for our organization.
                  </p>

                  <ul class="tags">
                    <li>
                      <a href="#">Inspiring</a>
                    </li>
                    <li>
                      <a href="#">Open</a>
                    </li>
                    <span class="empty_break"></span>
                    <li>
                      <a href="#">Innovative</a>
                    </li>
                    <li>
                      <a href="#">Sharing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content hide_temp">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/vision.svg"
                    alt="corporate_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Vision</h3>
                    <p>
                      We relentlessly champion innovation to to lead and
                      redefine the forefront of the global communication
                      industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/mission.svg"
                    alt="corporate_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Mission</h3>
                    <p>
                      Empower businesses worldwide with transformative solutions
                      to revolutionize global markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="box">
                <div class="img_wrap">
                  <img src="../../assets/images/goal.svg" alt="corporate_img" />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Goal</h3>
                    <p>
                      Optimize and unify communication resources to drive
                      unparalleled global expansion for businesses.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recognised_awards -->
    <section class="recognised_awards pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Recognised awards/achievements</h2>
        </div>
        <div class="content">
          <ul class="logos_wrap">
            <li>
              <a href="#">
                <img
                  src="../../assets/images/award_logo.png"
                  alt="award_logo"
                />
              </a>
            </li>
            <li>
              <a href="#">
                <img
                  src="../../assets/images/award_logo.png"
                  alt="award_logo"
                />
              </a>
            </li>
            <li>
              <a href="#">
                <img
                  src="../../assets/images/award_logo.png"
                  alt="award_logo"
                />
              </a>
            </li>
            <li>
              <a href="#">
                <img
                  src="../../assets/images/award_logo.png"
                  alt="award_logo"
                />
              </a>
            </li>
            <li>
              <a href="#">
                <img
                  src="../../assets/images/award_logo.png"
                  alt="award_logo"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- Worldwide pressence -->
    <section class="worldwide_sec pb_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Worldwide Presence</h2>
        </div>
        <div class="content">
          <div class="map_area">
            <div class="map_img_wrap">
              <img src="../../assets/images/map_1.png" alt="map" />
            </div>

            <div class="locations_wrap">
              <ul class="locations">
                <li
                  v-for="(location, index) in locations"
                  :key="index"
                  class="location"
                  @mouseover="showLocation(index)"
                  @mouseleave="hideLocation(index)"
                  :class="[
                    location.countryClass,
                    { active: activeLocation === index },
                  ]"
                >
                  <div class="lcmark">
                    <div class="icon">
                      <img
                        src="../../assets/images/location_mark.png"
                        alt="location_mark"
                      />
                    </div>
                    <h4 class="country">{{ location.country }}</h4>
                  </div>
                  <div class="info">
                    <h5>{{ location.country }}</h5>
                    <a
                      v-if="location.contactNumber"
                      :href="'tel:' + location.contactNumber"
                      class="tel"
                      ><span class="strong">Tel:</span>
                      {{ location.contactNumber }}</a
                    >
                    <p
                      v-if="location.address"
                      @click="copyTextNoInput(location)"
                    >
                      <span class="strong">Address: </span>
                      {{ location.address }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- History -->
    <section class="history_sec pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>A brief history of NXCLOUD</h2>
        </div>
        <div class="content">
          <div class="history_wrapper">
            <div class="inner">
              <ul class="history_listing">
                <li
                  :class="{ active: activeHistory === index }"
                  v-for="(history, index) in histories"
                  :key="index"
                  @mouseover="showHistory(index)"
                >
                  <div class="left">
                    <button data-history="history_1">
                      <span>{{ history.year }}</span>
                    </button>
                  </div>
                  <div class="right">
                    <div
                      class="text_wrap"
                      v-for="item in history.content"
                      :key="item"
                    >
                      <h3>{{ item.title }}</h3>
                      <p>{{ item.desc }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success"
                >Get Started</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "CompanyPage",
  data() {
    return {
      activeLocation: null,
      locations: [
        {
          country: "Shenzhen, China",
          countryClass: "shenzhen",
          contactNumber: "400-7800-900",
          address:
            "6/F Room601 Building A1, Kexing Science Park No .15 Keyuan Road, Nanshan District Shenzhen 518000, Guangdong Province China ",
        },
        {
          country: "Netherlands",
          countryClass: "netherlands",
          contactNumber: "",
          address: "",
        },
        {
          country: "Hong Kong",
          countryClass: "hongkong",
          contactNumber: "",
          address:
            " Room 803 8/F, Lai Cheong Factory Bldg., 479-479A Castle Peak Road, Cheung Sha Wan, Kowloon, Hong Kong",
        },
        {
          country: "Philippines",
          countryClass: "philippines",
          contactNumber: "",
          address: "",
        },
        {
          country: "Indonesia",
          countryClass: "indonesia",
          contactNumber: "",
          address: "",
        },
        {
          country: "Singapore",
          countryClass: "singapore",
          contactNumber: "",
          address: "8 Eu Tong Sen Street #24-82, The Central, Singapore 059818",
        },
        {
          country: "Malaysia",
          countryClass: "malaysia",
          contactNumber: "",
          address: "",
        },
        {
          country: "Vietnam",
          countryClass: "vietnam",
          contactNumber: "",
          address: "",
        },
        {
          country: "California, USA",
          countryClass: "california",
          contactNumber: "",
          address:
            " 1601 McCarthy Blvd Milpitas 95035, California United States of America",
        },
      ],
      activeHistory: 0,
      histories: [
        {
          year: 2018,
          content: [
            {
              title: "March",
              desc: "NXCLOUD is officially headquartered in Singapore, with branches and offices across the USA, Indonesia and Malaysia.",
            },
            {
              title: "August",
              desc: "NXCLOUD's independent R&D Cloud Communication Platform has made its official debut.",
            },
          ],
        },
        {
          year: 2019,
          content: [
            {
              title: "June",
              desc: "One of NXCLOUD’s flagship SaaS Systems - AICC is officially launched.",
            },
          ],
        },
        {
          year: 2021,
          content: [
            {
              title: "May",
              desc: "NXCLOUD becomes an official corporate member of the GSMA Association.",
            },
          ],
        },
        {
          year: 2022,
          content: [
            {
              title: "May",
              desc: "Appointed as the official Business Messaging Partner of Viber.",
            },
            {
              title: "August",
              desc: "Appointed as the official authorized and authenticated solution provider for WhatsApp Business.",
            },
          ],
        },
        {
          year: 2023,
          content: [
            {
              title: "January",
              desc: "Officially become a Whatsapp Select Business Solution Provider.",
            },
            {
              title: "April",
              desc: "Global rollout of NXCLOUD's NXLink SaaS product.",
            },
            {
              title: "September",
              desc: "NXCLOUD proudly served as a Gold Partner at ACC 2023.",
            },
            {
              title: "December",
              desc: "Appointed as the official business provider for Zalo.",
            },
          ],
        },
        {
          year: 2024,
          content: [
            {
              title: "July",
              desc: "NXCLOUD has been promoted to the Premier-level WhatsApp Official Business Solution Provider (BSP).",
            },
          ],
        },
      ],
    };
  },
  methods: {
    showLocation(index) {
      this.activeLocation = index; // Set active location index when hovering
    },
    hideLocation() {
      this.activeLocation = null; // Reset active location index when mouse leaves
    },
    showHistory(index) {
      this.activeHistory = index; // Set active History index when hovering
    },
    copyTextNoInput(location) {
      let text = location.address;
      // console.log(text);
      let textCopied = document.createElement("textarea");
      textCopied.value = text;
      document.body.appendChild(textCopied);
      textCopied.select();
      document.execCommand("copy");
      document.body.removeChild(textCopied);
      alert("Address:\n" + text);
    },
  },
};
</script>
