<template>
  <main>
    <!-- rates -->
    <section class="rates pb_200">
      <div class="container">
        <div class="row row-rates">
          <div class="col-lg-6 col-md-12 text-center">
            <img
              src="../../assets/images/pricing/price-figure.png"
              class="img-fluid pricing_hero"
              alt="price-figure"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="row row-rates-card-wrap">
              <div class="col-lg-6 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <!-- <h4 class="rates-hdng">Message</h4>
                      <div class="rates-figure">
                        <img src="assets/images/pricing/msg.png" alt="msg" />
                      </div> -->

                    <div class="Countries-wrap">
                      <div class="icon_item">
                        <i class="icon">
                          <img
                            src="../../assets/images/pricing/msg.png"
                            alt="icon"
                          />
                        </i>
                        <h4 class="Countries-hdng">消息</h4>
                      </div>
                      <ul>
                        <li class="text-dark text-center fs_16">
                          <strong>国家和价格（美元/条）</strong>
                        </li>
                        <li>
                          <div class="rates-list">
                            <p>印度尼西亚</p>
                            <p>0.2846</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>俄罗斯</p>
                            <p>0.2298</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>巴基斯坦</p>
                            <p>0.3320</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>越南</p>
                            <p>0.1021</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>沙特阿拉伯</p>
                            <p>0.1600</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>埃及</p>
                            <p>0.1926</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>尼日利亚</p>
                            <p>0.1978</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>菲律宾</p>
                            <p>0.1481</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>伊拉克</p>
                            <p>0.1759</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>印度</p>
                            <p>0.0537</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <!-- <h4 class="rates-hdng">Voice</h4>
                      <div class="rates-figure">
                        <img src="assets/images/pricing/call.png" alt="call" />
                      </div> -->

                    <div class="Countries-wrap">
                      <div class="icon_item">
                        <i class="icon">
                          <img
                            src="../../assets/images/pricing/call.png"
                            alt="icon"
                          />
                        </i>
                        <h4 class="Countries-hdng">语音</h4>
                      </div>
                      <ul>
                        <li class="text-dark text-center fs_16">
                          <strong>国家和价格（美元/分）</strong>
                        </li>
                        <li>
                          <div class="rates-list">
                            <p>印度尼西亚</p>
                            <p>0.0676</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>印度</p>
                            <p>0.0305</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>菲律宾</p>
                            <p>0.1435</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>越南</p>
                            <p>0.1080</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>哥伦比亚</p>
                            <p>0.0207</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>墨西哥</p>
                            <p>0.0082</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>泰国</p>
                            <p>0.0806</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>美国</p>
                            <p>0.0119</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>智利</p>
                            <p>0.0360-1.0500</p>
                          </div>
                        </li>

                        <li>
                          <div class="rates-list">
                            <p>秘鲁</p>
                            <p>0.0150-0.4000</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <p class="more_info text-center">
                  更多国家和地区的价格请联系牛信云工作人员。
                </p>
              </div>
              <div class="col-md-12">
                <div class="rates-btn">
                  <router-link to="/zh-sign-up" class="btn btn-success">
                    立即注册
                    <img
                      src="../../assets/images/sign-up.svg"
                      class="arrow-right"
                      alt="arrow-right"
                    />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
    
<script>
export default {
  name: "ZhPricingPage",
};
</script>